import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../App";
import Base from "../../Common/Components/Base";
import MonthPicker from "../../Common/Components/MonthPicker";
import "./Leave.css";
import { getLeaves, getLeavescount } from "./LeaveAPIcalls";

const Leave = () => {
  const { token, userData } = useContext(UserContext);
  const [leaves, setLeaves] = useState([]);
  const [count, setCount] = useState(0);
  const [date, setDate] = useState(new Date());
  console.log("date", date);
  useEffect(() => {
    getLeaves(token).then((res) => {
      setLeaves(res);
    });
  }, []);

  useEffect(() => {
    getLeavescount(token, date).then((res) => {
      console.log("res", res);
      setCount(res);
    });
  }, [date, token]);

  return (
    <Base>
      <div className="leave  w_85 mx-auto">
        <MonthPicker date={date} setDate={setDate} />
        <div className="leave_info">
          <div className="leave_info_text">
            <span>{count}</span>
            <span>Leave Taken</span>
          </div>
          <Link to="/leave/form" className="apply_btn">
            Apply Leave
          </Link>
        </div>
        <table className="leave_table table">
          <thead>
            <th>Applied For</th>
            <th>Status</th>
            <th>Leave Type</th>
            <th>Remark</th>
            <th>Applied On</th>
            <th>Approved By</th>
          </thead>

          {leaves.map((item, index) => {
            var Lmonth = new Date(item.appliedOn).getMonth();
            var Lyear = new Date(item.appliedOn).getFullYear();
            if (date == "") {
              return (
                <tr>
                  <td>{userData.name}</td>
                  <td>{item.leaveStatus}</td>
                  <td>{item.leaveType}</td>
                  <td>{item.remark}</td>
                  <td>{item.appliedOn.substring(0, 10)}</td>

                  <td>{item.approvedBy}</td>
                </tr>
              );
            } else {
              var month = new Date(date).getMonth();
              var year = new Date(date).getFullYear();
              if (Lmonth == month && Lyear == year) {
                return (
                  <tr>
                    <td>{userData.name}</td>
                    <td>{item.leaveStatus}</td>
                    <td>{item.leaveType}</td>
                    <td>{item.remark}</td>
                    <td>{item.appliedOn.substring(0, 10)}</td>

                    <td>{item.approvedBy}</td>
                  </tr>
                );
              }
            }
          })}
        </table>
      </div>
    </Base>
  );
};

export default Leave;
