import { base_route } from "../../backend";

export const createLeave = (data, token) => {
  return fetch(`${base_route}/leave/new`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "bearer-token": `${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const getLeavescount = (token, date) => {
  return fetch(`${base_route}/leave/getLeavebydate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "bearer-token": `${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ date }),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const getLeaves = (token) => {
  return fetch(`${base_route}/leave/myleaves`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
