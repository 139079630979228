import {Link} from "@material-ui/core";
import React from "react";
import "./Class.css";
import Class1Img1 from "../../../../Assets/ClassImg/Class1Img1.png";
import Class1Img2 from "../../../../Assets/ClassImg/Class1Img2.png";
import Class1Img3 from "../../../../Assets/ClassImg/Class1Img3.png";
import Class1Img4 from "../../../../Assets/ClassImg/Class1Img4.png";
import Class1Img5 from "../../../../Assets/ClassImg/Class1Img5.png";

const ClassOne = ({courseName, classNo}) => {
  return (
    <div className="class w_90 mx-auto">
      <div className=" d-flex justify-content-between">
        <p>STRICTLY CONFIDENTIAL</p>
        <p>TECHOKIDS INSTRUCTOR MANUAL</p>
      </div>
      <h1>TITLE PAGE</h1>
      <h4>
        <b>Key: </b>
        <span className="bg-warning">
          Teacher Does + Teacher Screen Shared
        </span>{" "}
        |{" "}
        <span className="bg-success ">
          Student Does + Student Screen Shared
        </span>
      </h4>
      <h4>
        <b>Today’s session plan:</b> Introduce coding, MIT Scratch
      </h4>
      <table class="table mt-5 ">
        <thead>
          <tr>
            <th>#</th>
            <th>To-do</th>
            <th>Min</th>
            <th>Resources</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Introduction</td>
            <td>5</td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>Activity: What is coding?</td>
            <td>10</td>
            <td>
              <Link to="https://scratch.mit.edu/projects/10128431">Link</Link>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Project: Animated Thank-you card</td>
            <td>30</td>
            <td>
              <Link to="https://scratch.mit.edu/projects/410940689">
                Sample (basic)
              </Link>
              <Link to="https://scratch.mit.edu/projects/390522798">
                Sample (advanced)
              </Link>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Wrap up </td>
            <td>5</td>
            <td></td>
          </tr>
          <tr>
            <td>5</td>
            <td>Parent interaction</td>
            <td>10</td>
            <td>
              <Link to="https://docs.google.com/document/d/1Grr2SA6AXVczHTTTCaunWfOTtjUcgqJp706bHG7LXtQ/edit">
                Link
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
      <h4 className="mt-5">
        <b>Course overview - a quick reminder of your student’s progress: </b>
      </h4>
      <table class="table mt-3">
        <thead>
          <tr>
            <th>Session</th>
            <th>Concept</th>
            <th>Project</th>
            <th>Skill</th>
            <th>Resources</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Trial</td>
            <td>Introduce Coding, MIT Scratch</td>
            <td>Animated Thank-you card</td>
            <td>Creativity, Problem solving</td>
            <td>
              <Link to="https://scratch.mit.edu/projects/388543077">
                Sample
              </Link>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>3</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>4</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>5</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <h1 className="mt-5">ARE YOU READY FOR YOUR SESSION?</h1>
      <h4>
        Click
        <Link
          className="mx-2"
          to="https://docs.google.com/document/d/1gKVC1KUm2m9hJEwEE72G3ikN91vE6cnnn84aRcsF840/edit"
        >
          here
        </Link>
        to check{" "}
      </h4>
      <h1 className="bg-warning mt-5">
        A - INTRODUCTION - 5 MINUTES - TEACHER WEBCAM
      </h1>
      <h2 className="mt-5">
        Introduction - <b> Objective</b>
      </h2>
      <ul>
        <li>- Engage and excite your student </li>
        <li>- Make him/ her comfortable </li>
        <li>
          - Introduce the concept of computers/ coding and ask related questions
          so you can customise the project
        </li>
      </ul>
      <h1 className="mt-5">
        Introduction - <b>Support</b>{" "}
      </h1>
      <h4>Ask open-ended (not Yes/ No) questions like:</h4>
      <ul className="mt-3">
        <li>- Which grade are you in?</li>
        <li>- Which school are you in ?</li>
        <li>- What do you do on the computer? Which games do you play?</li>
        <li>- Which is your favorite animated/ cartoon character movie?</li>
      </ul>
      <h4 className="mt-3">
        And build excitement based on the student’s answers. E.g.:
      </h4>
      <ul className="mt-3">
        <li>
          - If the student says “I play Mario,” say, “You can even create a
          Mario!”
        </li>
        <li>
          - If the student says “Esla from Frozen” say, “You can make your own
          princess on the computer and make her sing like Esla too!”
        </li>
      </ul>
      <h1 className="bg-warning mt-5">
        B - ACTIVITY - 10 MINUTES -{" "}
        <Link to="https://scratch.mit.edu/projects/10128431" className="mx-2">
          LINK
        </Link>{" "}
        - TEACHER SCREEN{" "}
      </h1>
      <h1>
        Activity - <b> Objective </b>
      </h1>
      <ul className="mt-3">
        <li>
          - Introduce the concept of ‘giving instructions,’ and ask the student
          to ‘give you instructions’ to solve the maze activity
        </li>
        <li>
          - Connect the dots from the maze activity (‘student giving teacher
          instructions’) to the concept of coding (‘student giving the computer
          instructions’){" "}
        </li>
        <li>
          - Inform the student that the computer does not understand
          instructions in English/ Hindi/ their language, but in a special
          language called “code”
        </li>
        <li>-Introduce the project (Animated Thank you Card) to come</li>
      </ul>

      <h1 className="mt-5">
        Activity - <b>Support</b>
      </h1>

      <ul className="mt-5">
        <h4>Introduce the concept of instructions with questions like </h4>
        <li className="pl-4">
          - What do you do after waking up in the morning?
        </li>
        <li className="pl-4">- Do your parents ask you to do something?</li>
      </ul>
      <ul className="mt-5">
        <h4>Introduce the concept of instructions with questions like </h4>
        <li className="pl-4">
          - You’re my navigator and I'm the driver. You have to tell me where to
          move - up, down, left, right - and I'll move in that direction and
          together we have to reach the goal.
        </li>
      </ul>
      <ul className="mt-5">
        <h4>
          Ask the student if he/ she can solve the puzzle without the keyboard
          like{" "}
        </h4>
        <li className="pl-4">
          - This time as you give instructions, I will not touch the keyboard -
          can you talk to the computer and tell it what to do? Does it work?
        </li>
      </ul>
      <ul className="mt-5">
        <h4>Inform the student why using the keyboard is necessary</h4>
        <li className="pl-4">
          - Computer can not understand English, we need to help the computer
          understand english. This is called coding and we are going to start to
          learn coding by first creating a beautiful thank you card
        </li>
      </ul>
      <img src={Class1Img1} alt="img1" className="img-fluid" />
      <h1 className="mt-5">
        Activity - <b> Checklist</b>
      </h1>
      <ul>
        <li>
          ❏ Test the student’s understanding - Ask him/ her to explain coding to
          you{" "}
        </li>
        <li>❏ Tip coins for right answer</li>
        <li>
          ❏ Introduce the coming project (Animated Thank You card) and customise
          it (e.g. Thank you Mom/ Thank you Dad - based on what the student
          wants
        </li>
      </ul>
      <h1>
        <span className="bg-success">
          C - PROJECT - 25 MINUTES -{" "}
          <Link to="https://scratch.mit.edu/projects/388543077">LINK</Link> -
        </span>
        <span className="bg-warning">TEACHER SCREEN FOR FIRST LETTER (T),</span>
        <span className="bg-success">STUDENT SCREEN FOR ALL OTHER LETTERS</span>
      </h1>
      <ul className="mt-5">
        <h4>
          Project - <b> Objective</b>
        </h4>
        <li className="pl-3">- Introduce student to Scratch </li>
        <li className="pl-3">
          - Teach student how to do the following in Scratch
        </li>
        <ul className="pl-5">
          <li className="pl-3">- Create a project</li>
          <li className="pl-3">- Change / add/ remove Sprites</li>
          <li className="pl-3">- Change back-ground</li>
          <li className="pl-3">- Animate a Sprite (by changing the color)</li>
        </ul>
      </ul>
      <h1 className="mt-5">
        Project - <b>Support</b>{" "}
      </h1>
      <h4 className="bg-warning">Start by sharing Teacher Screen</h4>
      <ul className="mt-2">
        <h4>
          Introduce Scratch and guide the student on how to Create a project
        </h4>
        <ul className="p-4">
          <li>
            - “We’ll be working on a platform called Scratch where we will put
            together blocks to make our thank you card”
          </li>
          <li>
            - “Open the link I have shared with you scratch.mit.edu and click on
            ‘Create’”
          </li>
        </ul>
      </ul>
      <h4 className="my-4">
        Switch to student screen for the remaining part of the activity
      </h4>
      <ul className="mt-4 ">
        <h4>Introduce Sprite and show how to add Sprites</h4>
        <li className="pl-4">
          - “The cat is called a Sprite and we can make it sing or move or talk
          or even become smaller or larger. Do you want it in your card?”{" "}
        </li>
        <li className="pl-4">
          - “We can add more Sprites by clicking on the plus sign - I will add
          T, H, A, N, K”{" "}
        </li>
      </ul>
      <ul>
        <b>
          <li>
            Tip: The three different sections of the Scratch platform can be
            called{" "}
          </li>
          <li className="pl-4">
            1) Blocks palette on the left can be called computer language
          </li>
          <li className="pl-4">
            2) Coding area in the middle can be called computers brain
          </li>
          <li className="pl-4">
            3) The Stage with the sprites can be called the stage where the
            animation/game takes place.
          </li>
        </b>
      </ul>
      <h4 className="mt-4">Show how to change background</h4>
      <h4 className="mt-4">
        Show how to animate the letter T to change its color
      </h4>
      <img src={Class1Img2} alt="img2" className="img-fluid" />
      <h4>
        Ask the child to repeat the process for the remaining letters like “Now
        it’s your turn - you share your screen - and show me how you can add
        Sprites and change the colors”
      </h4>
      <img src={Class1Img3} alt="img3" className="img-fluid" />
      <h4>
        {" "}
        <b>
          Tip: We can also help students build name cards instead of greeting /
          thank you cards. Students will be able to find the letters to their
          names faster
        </b>{" "}
      </h4>
      <ul className="mt-5">
        <h4>
          Project - <b> Checklist</b>
        </h4>
        <li>
          ❏ Tip coins for to keep the child engaged and appreciate his/ her
          ability to pick up a new concept and execute it quickly
        </li>
        <li>
          ❏ Test the student’s understanding - Ask him/ her to explain ‘Sprite’
          to you
        </li>
        <li>
          ❏ Add challenges if the student is able to finish the project before
          time - teach him/ her to make the Sprite move, add new Sprites
          (animals or humans) and make them “converse” with each other
        </li>
      </ul>
      <h1 className="mt-5">
        Upload & Feedback - <b> Support </b>
      </h1>
      <h4>Walk the student through the upload process</h4>
      <ul className="pl-4">
        <li>
          - This is an amazing project you have created! Would you like to show
          it to your friends and family?
        </li>
        <li>
          - Login to Scratch <b> using the account you have created.</b>
        </li>
        <li>
          - Press <b> Share </b> on top of the Scratch screen (image below)
        </li>
        <li>
          - Press <b> Copy Link </b> on the bottom-right of the Scratch screen
          (image below)
        </li>
        <li>
          - I have shared a form with you, all you have to do is{" "}
          <b> copy the link from the Scratch project and paste it here </b>
        </li>
        <li>
          Great! I gave you 50 more coins because you completed and uploaded
          your project!{" "}
        </li>
      </ul>
      <img src={Class1Img4} alt="img-3" className="mt-2 img-fluid" />
      <img src={Class1Img5} alt="img-4" className="mt-2 img-fluid" />
      <h4 className="mt-4">Collect feedback</h4>
      <ul className="pl-4">
        <li>
          - Now I’ll show you where you can find your project, but before that,
          I’ve sent you a short form - can you fill this?
        </li>
        <li>
          - You have to tell me how much fun you had - 1 means no fun, and 5
          means lots of fun. And how much did you learn - 1 means you didn’t
          learn a lot, 5 means you learnt a lot. And in the big box, you can
          write anything about you liked
        </li>
        <li>
          - And 50 more points for being such a nice student and giving feedback
        </li>
      </ul>
      <h4 className="mt-4">
        Show the child his/ her project on the Techokids website
      </h4>
      <ul className="pl-4">
        - Go to Docs (on the left side) and press on ‘Your Projects’ - can you
        see your own project on the top? Everyone can see your project! They can
        also like it!
      </ul>
      <h1 className="mt-5">Upload & Feedback - Checklist</h1>
      <ul className="pl-4">
        <li>❏ Get the student to login to Scratch</li>
        <li>❏ Upload the project</li>
        <li>❏ Collect feedback</li>
      </ul>
      <h1 className="mt-5 bg-warning">
        D - PARENT INTERACTION - 15 MINUTES -{" "}
        <Link to="https://docs.google.com/document/d/1fei9bW4yongl1S0e60yKLaDq7U2mcJpSCtfflpywEjA/edit?usp=sharing">
          LINK
        </Link>{" "}
        - TEACHER SCREEN
      </h1>
      <h2 className="mt-4">
        Parent Interaction - <b> Objective</b>
      </h2>
      <h4>
        Build trust in Techokids offerings and convert a parent whose kid has
        just completed a demo session with Techokids into a paying customer
      </h4>

      <h2 className="mt-4">
        Parent Interaction - <b> Support </b>
      </h2>
      <h4>Ask the student to call his/ her parents</h4>
      <h4 className="pl-4">
        - Can you call your mom or dad? I want to show them your project and
        talk to them for a few minutes
      </h4>
      <h4 className="mt-4">
        As the kid calls his/ her parents, open the ‘Docs’ tab on the left hand
        side of your screen
      </h4>
      <h4 className="mt-4">
        Here you will find links to all projects you need to show the parents
      </h4>
      <h1 className="mt-5">Parent Interaction - Checklist</h1>
      <ul className="pl-4">
        <li>❏ Share positive FEEDBACK</li>
        <li>❏ Understand parents’ EXPERIENCE and answer their questions</li>
        <li>❏ Show PROJECTS</li>
        <li>❏ Pain the BIG PICTURE</li>
        <li>❏ Inform about NEXT STEPS</li>
      </ul>
      <div className="iframe_container">
        <iframe
          src="https://www.youtube.com/embed/iJgL_Ke_aEU"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          className="responsive-iframe mt-5"
          title="class 1"
        ></iframe>
      </div>
      <div className="iframe_container mt-5">
        {/* <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vSMQSVkz8Jik9UTmTIReIJA1pmoWuzw9GtPA-e-1W8jckwqSVwTtxTssNsbcVS01JZTRFhUm4cjNoNg/embed?start=false&loop=false&delayms=5000"
          frameborder="0"
          className="responsive-iframe "
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe> */}
      </div>
    </div>
  );
};

export default ClassOne;
