import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Base from "../../../../Common/Components/Base";
import TkButton from "../../../../Common/Components/TkButton";
import "./Profile.css";
import { MdLocationOn } from "react-icons/md";
import { FaGraduationCap } from "react-icons/fa";
import certificate from "../../../../Assets/Profile/certificate.png";
import { FiDownload } from "react-icons/fi";
import ProfileItem from "../../Components/ProfileItem";
import BackNavigation from "../../../../Common/Components/BackNavigation";
import BadgeModal from "../../Components/BadgeModal";
import { UserContext } from "../../../../App";
import { _arrayBufferToBase64 } from "../../../../Common/Helper/index";
import badgeOne from "../../../../Assets/Profile/badgeOne.png";
import badgeTwo from "../../../../Assets/Profile/badgeTwo.png";
import badgeThree from "../../../../Assets/Profile/badgeThree.png";
import badgeFour from "../../../../Assets/Profile/badgeFour.png";
import badgeFive from "../../../../Assets/Profile/badgeFive.png";
import badgeSix from "../../../../Assets/Profile/badgeSix.png";
import badgeOneLock from "../../../../Assets/Profile/badgeOneLock.png";
import badgeTwoLock from "../../../../Assets/Profile/badgeTwoLock.png";
import badgeThreeLock from "../../../../Assets/Profile/badgeThreeLock.png";
import badgeFourLock from "../../../../Assets/Profile/badgeFourLock.png";
import badgeFiveLock from "../../../../Assets/Profile/badgeFiveLock.png";
import badgeSixLock from "../../../../Assets/Profile/badgeSixLock.png";
import { BsArrowRightShort } from "react-icons/bs";
import {
  getProfilePic,
  updateProfile,
  updateProfilePic,
  getClasses,
} from "./ProfileAPIcalls";
const Profile = () => {
  const { userData, token } = useContext(UserContext);
  const history = useHistory();
  const defaultPic =
    "https://cet.ecu.edu/construction/wp-content/pv-uploads/sites/270/2019/02/man-user_150.jpg";
  const [showModal, setshowModal] = useState(false);
  //const { userData, token, loading, setLoading } = useContext(UserContext);
  const [imageSrc, setimageSrc] = useState(null);
  const [mylang, setLang] = useState([]);
  const [myCourses, setCourses] = useState([]);
  const [classCount, setClasscount] = useState(0);
  const onModalBtnClick = () => {
    setshowModal(!showModal);
  };

  useEffect(async () => {
    if (userData.profilePic != undefined) {
      await getProfilePic(userData.profilePic).then((binaryImg) => {
        let base64String =
          binaryImg != undefined && _arrayBufferToBase64(binaryImg.data);
        if (base64String) {
          const binary = `data:image/gif;base64,${base64String}`;
          setimageSrc(binary);
        } else {
          setimageSrc(defaultPic);
        }
      });
      await getClasses(token).then((count) => {
        console.log("count", count);
        setClasscount(count);
      });
    }
  }, [userData.profilePic]);

  useEffect(async () => {
    //console.log("mycourse", myCourses);
    //console.log("mu languages", mylang);
    if (userData != undefined || userData != null) {
      await setLang(userData.languages);

      await setCourses(userData.myCourses);
    }
  }, [userData]);

  const userName = (name) => {
    if (name) {
      var words = name.split(" ");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      return words.join(" ");
    } else {
      return "";
    }
  };

  const lastestBadge = () => {
    if (classCount >= 0 && classCount < 100) {
      return badgeOne;
    } else if (classCount >= 100 && classCount < 250) {
      return badgeTwo;
    } else if (classCount >= 250 && classCount < 500) {
      return badgeThree;
    } else if (classCount >= 500 && classCount < 750) {
      return badgeFour;
    } else if (classCount >= 750 && classCount < 1000) {
      return badgeFive;
    } else {
      return badgeSix;
    }
  };
  const showBagdes = () => {
    if (classCount >= 0 && classCount < 100) {
      return (
        <>
          <img src={badgeOne} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeTwoLock} alt="badge" className="teacher_badge_sm" />
          <img src={badgeThreeLock} alt="badge" className="teacher_badge_sm" />
        </>
      );
    } else if (classCount >= 100 && classCount < 250) {
      return (
        <>
          <img src={badgeOne} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeTwo} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeThreeLock} alt="badge" className="teacher_badge_sm" />
          <img src={badgeFourLock} alt="badge" className="teacher_badge_sm" />
        </>
      );
    } else if (classCount >= 250 && classCount < 500) {
      return (
        <>
          <img src={badgeOne} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeTwo} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeThree} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeFourLock} alt="badge" className="teacher_badge_sm" />
          <img src={badgeFiveLock} alt="badge" className="teacher_badge_sm" />
        </>
      );
    } else if (classCount >= 500 && classCount < 750) {
      return (
        <>
          <img src={badgeOne} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeTwo} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeThree} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeFour} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeFiveLock} alt="badge" className="teacher_badge_sm" />
          <img src={badgeSixLock} alt="badge" className="teacher_badge_sm" />
        </>
      );
    } else if (classCount >= 750 && classCount < 1000) {
      return (
        <>
          <img src={badgeOne} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeTwo} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeThree} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeFour} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeFive} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeSixLock} alt="badge" className="teacher_badge_sm" />
        </>
      );
    } else {
      return (
        <>
          <img src={badgeOne} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeTwo} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeThree} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeFour} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeFive} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
          <img src={badgeSix} alt="badge" className="teacher_badge_sm" />
          <div className="progress_next_icon">
            <BsArrowRightShort />
          </div>
        </>
      );
    }
  };
  return (
    <Base>
      <BackNavigation />

      <div className="profile d-flex flex-sm-row flex-column-reverse w_90 mx-auto">
        <div className="profile_info col-lg-8 col-sm-7  col-12">
          <ProfileItem
            title={"About Me"}
            content={<p className="profile_text mt-2">{userData.about}</p>}
          />
          <ProfileItem
            title={"Hobbies"}
            content={<p className="profile_text mt-2">{userData.hobbies}</p>}
          />
          <ProfileItem
            title={"My Courses"}
            content={
              <div className="d-flex flex-wrap">
                {Array.from(new Set(myCourses)).map((item, index) => {
                  return <span className="badge_item">{item}</span>;
                })}
              </div>
            }
          />
          <ProfileItem
            title={"Languages Spoken"}
            content={
              <div className="d-flex flex-wrap">
                {Array.from(new Set(mylang)).map((item) => {
                  return <span className="badge_item">{item}</span>;
                })}
              </div>
            }
          />
          <ProfileItem
            title={"Badges"}
            content={
              <div className="smbadge_section">
                <div className="smbadge_container scrollable d-flex align-items-center ">
                  {showBagdes()}
                </div>
                <div>
                  <TkButton
                    name={"Explore More"}
                    _class={"mt-2"}
                    style={{ backgroundColor: "#101730", height: 35 }}
                    _onBtnClick={() => onModalBtnClick()}
                  />
                </div>
              </div>
            }
          />
          <ProfileItem
            title={"Certificate Earn"}
            content={
              <div className="certificate">
                <img src={certificate} alt="" />
                <span className="mt-3 "> Game Developer Certificate</span>
                <TkButton
                  name={"Download"}
                  style={{ backgroundColor: "#00B23B", width: 120, height: 30 }}
                  _class={"sm mt-2"}
                  // _onBtnClick={action.handle}
                  Ricon={<FiDownload />}
                />
              </div>
            }
          />
        </div>
        <div className="profile_info_box d-flex flex-column col-lg-4 col-sm-4 col-12  align-items-center mx-auto">
          <img
            className="profile_pic"
            src={
              imageSrc === undefined || imageSrc === null
                ? defaultPic
                : imageSrc
            }
            alt="profile_img"
          />
          <TkButton
            name={"Edit Profile"}
            style={{ backgroundColor: "#00B23B" }}
            _onBtnClick={() => history.push("/setting/profile/edit")}
          />

          <h5 className="theme_color mt-4">
            <b>{userName(userData.name)}</b>
          </h5>
          <span className="theme_color">{userData.employeeCode}</span>
          <span className="theme_color mb-5">{userData.designation}</span>
          <div className="d-flex flex-column">
            <span>
              <MdLocationOn size={20} />
              <b className="px-1">{userData.city}</b>
            </span>
            <span>
              <FaGraduationCap size={20} />
              <b className="px-1">{userData.graduation}</b>
            </span>
          </div>

          <img src={lastestBadge()} alt="badge" className="teacher_badge" />
        </div>
        <BadgeModal showModal={showModal} closeModal={onModalBtnClick} />
      </div>
    </Base>
  );
};

export default Profile;
