import React, { useState, useContext, useEffect } from "react";
import BackNavigation from "../../../../Common/Components/BackNavigation";
import Base from "../../../../Common/Components/Base";
import TkDropDown from "../../../../Common/Components/DropDown";
import TkButton from "../../../../Common/Components/TkButton";
import ProfileInput from "../../Components/ProfileInput";
import { languagesData, coursesData } from "./data";
import "./Profile.css";
import { ImCross } from "react-icons/im";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import DayPicker from "../../../Leave/DayPicker";
import { UserContext } from "../../../../App";
import { useHistory } from "react-router-dom";
import StatusModal from "../../../../Common/Components/StatusModal";
import Overlay from "../../../../Common/Components/Overlay";
import csc from "country-state-city";
import {
  getProfilePic,
  updateProfile,
  updateProfilePic,
} from "./ProfileAPIcalls";
import { _arrayBufferToBase64 } from "../../../../Common/Helper/index";
import startOfYesterday from "date-fns/startOfYesterday/index";
const EditProfile = () => {
  const [language, setLanguage] = useState("Select Language");
  const [courses, setCourses] = useState("Select Courses");
  const [langList, setlangList] = useState([]);
  const [courseList, setcourseList] = useState([]);
  const [contactValue, setContactValue] = useState(null);
  const [countryList, setCountryList] = useState();
  const [stateList, setStateList] = useState();
  const [cityList, setCityList] = useState();

  const history = useHistory();

  const defaultProfilePic =
    "https://cet.ecu.edu/construction/wp-content/pv-uploads/sites/270/2019/02/man-user_150.jpg";

  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: "",
    statusText: "",
  });

  const [values, setValues] = useState({
    error: "",
    success: false,
  });
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    city: "",
    state: "",
    country: "",
    dob: "",
    gender: "",
    about: "",
    phone: {
      countryCode: null,
      number: null,
    },
    hobbies: "",
    graduation: "",
    languages: [],
    myCourses: [],
    designation: "",
    employeeCode: "",
    meetingLink: "",
    profilePic: "",
    profilePicInfo: "",
  });

  const {
    name,
    email,
    city,
    state,
    country,
    dob,
    gender,
    about,
    phone,
    hobbies,
    graduation,
    designation,
    employeeCode,
    languages,
    myCourses,
    meetingLink,
    profilePic,
    profilePicInfo,
  } = userInfo;
  const { error, success } = values;
  const [contactNo, setContactNo] = useState("");
  const [photoid, setPhotoid] = useState("");
  const [profilePicUri, setProfilePicUri] = useState("");
  const [date, setDate] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [stateCode, setStateCode] = useState("");
  useEffect(async () => {
    if (date != "") {
      console.log("date", typeof date);
      await setUserInfo((prev) => ({ ...prev, dob: date }));
    }
  }, [date]);
  const handleOnChange = (field) => (event) => {
    if (field === "country") {
      let data = event.target.value.split(" ");
      let code = data.pop(-1);
      setUserInfo({ ...userInfo, [field]: data.toString() });
      const list = csc.getStatesOfCountry(code);
      setCountryCode(code);
      console.log("list", list);
      setStateList(list);
      return;
    }

    if (field == "state") {
      let data = event.target.value.split(" ");
      let code = data.pop(-1);
      console.log("xe data", code);
      setUserInfo({ ...userInfo, [field]: data.toString() });
      const list = csc.getCitiesOfState(countryCode, code);
      console.log("list", list);
      setCityList(list);
      return;
    }
    setUserInfo({ ...userInfo, [field]: event.target.value });
    // console.log("userInfo", userInfo);
  };
  const { userData, token, loading, setLoading } = useContext(UserContext);
  useEffect(() => {
    if (typeof contactNo !== "undefined") {
      setUserInfo({
        ...values,
        phone: {
          // countryCode: parseInt(contactNo.toString().substring(0, 3)),
          number: parseInt(contactNo.toString()),
        },
        gender: userData.gender,
      });
    }
  }, [contactNo]);
  useEffect(() => {
    if (userData != undefined) {
      setUserInfo({
        ...values,
        name: userData.name,
        email: userData.email,
        city: userData.city,
        state: userData.state,
        country: userData.country,
        dob: userData.dob,
        gender: userData.gender,
        about: userData.about,
        phone: {
          countryCode: "91",
          number: userData.phone.number.toString(),
        },
        hobbies: userData.hobbies,
        myCourses: userData.myCourses,
        languages: userData.languages,
        graduation: userData.graduation,
        designation: userData.designation,
        employeeCode: userData.employeeCode,
        meetingLink: userData.meetingLink,
        profilePic: userData.profilePic,
      });
      setContactNo(userData.phone.number.toString());
      setDate(userData.dob);
    }
  }, [userData]);
  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await onUpdateProfile();
  };

  const onPhotoUpload = () => {
    if (profilePicInfo != undefined) {
      updateProfilePic(profilePicInfo).then(
        (data) => {
          setUserInfo((prev) => ({ ...prev, profilePic: data.image }));
        },
        setStatusModal({
          ...statusModal,
          showStatusModal: true,
          status: "success",
          statusText: "Photo Uploaded",
        })
      );
    } else {
      setStatusModal({
        ...statusModal,
        showStatusModal: true,
        status: "error",
        statusText: "Photo not selected",
      });
    }
  };
  useEffect(() => {
    if (!countryList) {
      setCountryList(csc.getAllCountries());
    }
  }, []);
  useEffect(() => {
    if (profilePic != undefined) {
      if (profilePicUri.substring(0, 4) != "blob") {
        getProfilePic(profilePic).then((binaryImg) => {
          let base64String =
            binaryImg != undefined && _arrayBufferToBase64(binaryImg.data);
          if (base64String) {
            const binary = `data:image/gif;base64,${base64String}`;
            setProfilePicUri(binary);
          } else {
            setProfilePicUri(defaultProfilePic);
          }
        });
      }
    }
  }, [profilePic, profilePicUri]);

  const onUpdateProfile = async () => {
    // console.log("userInfo on save", userInfo);
    await updateProfile(userInfo, token).then((data) => {
      window.location.reload();
    }, history.goBack());
  };

  const handleLanguageClick = async (lang) => {
    setLanguage(lang);
    await setlangList((oldArr) => [...oldArr, lang]);
    await setUserInfo((prev) => ({ ...prev, languages: langList }));
  };
  const handleCourseClick = async (course) => {
    setCourses(course);
    await setcourseList((oldArr) => [...oldArr, course]);
    await setUserInfo((prev) => ({ ...prev, myCourses: courseList }));
  };
  const popLangItem = (index) => {
    setlangList(langList.filter((item, i) => i !== index));
  };

  const popCourseItem = (index) => {
    setcourseList(courseList.filter((item, i) => i !== index));
  };
  const handleOnPhotoChange = (field) => (event) => {
    const value =
      field === "photo" ? event.target.files[0] : event.target.value;
    setProfilePicUri(URL.createObjectURL(value));
    setUserInfo({
      ...userInfo,
      profilePicInfo: value,
    });
  };
  return (
    <Base>
      {loading && <Overlay />}
      <BackNavigation />
      <div className="profile_edit d-flex flex-sm-row flex-column w_90 mx-auto ">
        <div className="p_60 col-sm-6 col-12 d-flex align-items-center flex-column">
          <img
            className="profile_pic"
            src={
              profilePicUri === undefined || profilePicUri === ""
                ? defaultProfilePic
                : profilePicUri
            }
            alt="profilepic"
          />
          <div className="d-flex justify-content-between profile_pic_option">
            <div className="upload_pic">
              <label>
                Select
                <input
                  type="file"
                  className="ticket_attach"
                  onChange={handleOnPhotoChange("photo")}
                  name="photo"
                  accept="image"
                />
              </label>
            </div>

            <button className="upload_pic" onClick={onPhotoUpload}>
              <b>Upload</b>
            </button>
          </div>
          <ProfileInput
            label="Email"
            placeholder="Email"
            type="readonly"
            onChange={handleOnChange("email")}
            value={email}
          />
          <ProfileInput
            label="Country"
            type="selectCountry"
            placeholder="Country"
            countryList={countryList}
            onChange={handleOnChange("country")}
            value={country}
          />
          <ProfileInput
            label="State"
            type="selectState"
            placeholder="State"
            stateList={stateList}
            onChange={handleOnChange("state")}
            value={state}
          />
          <ProfileInput
            label="City"
            type="selectCity"
            state={startOfYesterday}
            placeholder="City"
            cityList={cityList}
            onChange={handleOnChange("city")}
            value={city}
          />

          <ProfileInput
            label="About Me"
            type="textarea"
            placeholder="About Me"
            onChange={handleOnChange("about")}
            value={about}
          />
        </div>
        <div className="p_60 col-sm-6 col-12 d-flex align-items-center flex-column">
          <ProfileInput
            label="Full Name"
            placeholder="Full Name"
            type="readonly"
            value={name}
            onChange={handleOnChange("name")}
          />
          <ProfileInput
            label="Code"
            type="readonly"
            value={employeeCode}
            type="readonly"
            onChange={handleOnChange("employeeCode")}
          />
          <ProfileInput
            label="Designation"
            type="readonly"
            value={designation}
            onChange={handleOnChange("graduation")}
          />
          <div className="profile_edit_input">
            <label>Contact no</label>
            <PhoneInput
              value={`+91${contactNo}`}
              onChange={setContactNo}
              defaultCountry="IN"
              name="phone"
              autoComplete="on"
              maxlength="15"
              readOnly
            />
          </div>
          <div className="profile_edit_input">
            <DayPicker label="DOB" date={date} setDate={setDate} />
          </div>
          <div className="profile_edit_input">
            <label>
              <b> Gender</b>
            </label>
            <ul
              className="d-flex profile_edit_radio"
              onChange={handleOnChange("gender")}
            >
              <li>
                <input
                  type="radio"
                  value="male"
                  name="gender"
                  id="radio1"
                  checked={gender == "male" && true}
                />
                <label for="radio1">Male</label>
              </li>
              <li>
                <input
                  type="radio"
                  value="female"
                  name="gender"
                  id="radio2"
                  checked={gender == "female" && true}
                />
                <label for="radio2">Female</label>
              </li>
              <li>
                <input
                  type="radio"
                  value="others"
                  name="gender"
                  id="radio3"
                  checked={gender == "others" && true}
                />
                <label for="radio3">Others</label>
              </li>
            </ul>
          </div>
          <div className="profile_edit_input">
            {/*      <TkDropDown
              label="Language Spoken"
              style={{ backgroundColor: "#e5e5e5", width: "100%" }}
              _onClick={handleLanguageClick}
              value={language}
              items={[...languagesData]}
            />
            */}
            <label>Language Spoken</label>
            <div className="d-flex flex-wrap mt-2 ">
              {userData?.languages?.map((item, index) => {
                return (
                  <span className="badge_item" key={index}>
                    <span className="mr-1"> {item}</span>
                  </span>
                );
              })}
            </div>
          </div>
          <div className="profile_edit_input">
            {/*hjadf
            <TkDropDown
              label="Your Courses"
              style={{ backgroundColor: "#e5e5e5", width: "100%" }}
              _onClick={handleCourseClick}
              value={courses}
              items={[...coursesData]}
            />*/}
            <label>Your Courses</label>
            <div className="d-flex flex-wrap mt-2">
              {userData?.myCourses?.map((item, index) => {
                return (
                  <span className="badge_item" key={index}>
                    <span className="mr-1"> {item}</span>
                    {/* <ImCross size={8} /> */}
                  </span>
                );
              })}
            </div>
          </div>

          <ProfileInput
            label="Graduation"
            type="textarea2"
            placeholder="Graduation"
            value={graduation}
            onChange={handleOnChange("graduation")}
          />

          <ProfileInput
            label="Hobbies"
            type="textarea"
            placeholder="Hobbies"
            value={hobbies}
            onChange={handleOnChange("hobbies")}
          />
          <div className="w_90 d-flex justify-content-around">
            <TkButton
              name={"Save"}
              type={"submit"}
              style={{ backgroundColor: "#00B23B" }}
              // _onBtnClick={() => onModalBtnClick("Feedback")}
              _onBtnClick={(e) => onSubmit(e)}
            />
            <TkButton
              name={"Back"}
              type={"submit"}
              style={{ backgroundColor: "#00B23B" }}
              _onBtnClick={() => history.goBack()}
            />
          </div>
        </div>
        <StatusModal
          showModal={statusModal.showStatusModal}
          text={statusModal.statusText}
          status={statusModal.status}
          closeModal={() =>
            setStatusModal({ ...statusModal, showStatusModal: false })
          }
        />
      </div>
    </Base>
  );
};

export default EditProfile;
