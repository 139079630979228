import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import TkButton from "../../../Common/Components/TkButton";
import { setPassword } from "../DashboardAPICalls";
import "./components.css";
import StatusModal from "../../../Common/Components/StatusModal";
import { validatePassword } from "../../../Common/Helper";

const PasswordModal = ({ showModal, closeModal, email, token }) => {
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
    error: false,
    success: false,
  });

  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: "",
    statusText: "",
  });

  const { password, confirmPassword, error, success } = values;

  const handleOnChange = (field) => (event) => {
    setValues({ ...values, [field]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validatePassword(password)) {
      setValues({
        ...values,
        error:
          "Password should have minimum eight characters, at least one letter and one number",
      });
    } else if (password != confirmPassword) {
      setValues({ ...values, error: "Password don't match" });
    } else {
      setValues({ ...values, error: false });
      setPassword(password, token).then((data) => {
        if (data) {
          setStatusModal({
            ...statusModal,
            showStatusModal: true,
            status: "success",
            statusText: "Password Created",
          });
          window.location.reload();
        }
      });
    }
  };
  return (
    <div className={`modal password   ${showModal && "show_modal"}`}>
      <div className="modal_content  ">
        <ImCross size={15} onClick={() => closeModal()} />
        <div className="d-flex justify-content-center align-items-center flex-column">
          <>
            <h5>Create your unique password</h5>
            <form className="password_modal_form">
              <div className="form-group password_modal">
                <label>Email</label>
                <input
                  type="text"
                  placeholder="email"
                  className="email"
                  value={email}
                  readOnly
                />
              </div>
              <div className="form-group password_modal">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={handleOnChange("password")}
                />
              </div>
              <div className="form-group password_modal">
                <label>Confirm Password</label>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={handleOnChange("confirmPassword")}
                />
              </div>

              <TkButton
                name={"Submit"}
                style={{ backgroundColor: "#00b4db" }}
                _class="mt-5"
                _type="submit"
                _onBtnClick={(e) => handleSubmit(e)}
              />
            </form>
            <h5 className="modal_error">{error}</h5>
          </>
        </div>
      </div>
      <StatusModal
        showModal={statusModal.showStatusModal}
        text={statusModal.statusText}
        status={statusModal.status}
        closeModal={() => {
          setStatusModal({ ...statusModal, showStatusModal: false });
        }}
      />
    </div>
  );
};

export default PasswordModal;
