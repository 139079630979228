import { base_route } from "../../backend";

export const getDataBydate = (token, date) => {
  return fetch(`${base_route}/dashboard/teacher/myearningsbydate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
    body: JSON.stringify({ date }),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
