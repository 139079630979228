import React, { useState } from "react";
import "./Components.css";
import { weekData } from "./helper";

const DayCalendar = ({
  onDateSelect,
  date,
  slots,
  setSlots,
  localSlot,
  setLocalSlot,
}) => {
  const [IsSelected, setIsSelected] = useState(false);

  const check = (date) => {
    var d = date == undefined ? new Date() : date;
    if (slots.filter((e) => e.slot === d).length > 0) {
      let obj = slots.find((o) => o.slot === d);
      if (obj.studentName == "") {
        return "slot_available";
      } else if (
        obj.studentName != "" &&
        (obj.halfCompleted || obj.cancelled)
      ) {
        return "slot_cancel";
      } else if (obj.studentName != "" && obj.classtype == "paid") {
        return "slot_paid_booked";
      } else {
        return "slot_booked";
      }
    } else if (localSlot.filter((e) => e.slot === d).length > 0) {
      return "slot_locked";
    } else {
      return "";
    }
  };

  const bookedSlotInfo = (date) => {
    if (slots.filter((e) => e.slot === date).length > 0) {
      let obj = slots.find((o) => o.slot === date);
      if (obj.studentName != "") {
        return (
          <div className="booked_slot_data">
            <h5>S - {obj.studentName}</h5>
            <h5>P - {obj.parentName}</h5>
            <h5>G - {obj.grade}</h5>
            <h5>R - {obj.remark}</h5>
          </div>
        );
      } else {
        return <h6>Available</h6>;
      }
    } else {
      return "";
    }
  };
  //console.log("slots", slots);
  const handleOnClick = (index, time) => {
    var d = date._d == undefined ? new Date() : date._d;
    var selected = new Date(d).toDateString() + " " + time;
    var dayAfter31 = new Date().getTime() + 2678400000;
    var dayAfter2 = new Date().getTime() + 48 * 60 * 60 * 1000;
    // console.log("myslots", slots);
    var curr = new Date(selected).getTime();
    if (curr > dayAfter31) {
      return; //to prevent booking after 31 day
    }
    if (curr < dayAfter2) {
      return; //to prevent booking before day after tomorrow
    }

    if (slots.filter((e) => e.slot === selected).length > 0) {
      return;
    } else if (localSlot.filter((e) => e.slot === selected).length > 0) {
      const newdata = localSlot.filter((item) => item.slot !== selected);
      setLocalSlot(newdata);
    } else {
      var newslot = { slot: selected };
      setLocalSlot((prev) => [...prev, newslot]);
    }
    onDateSelect({ date: date, time: time });
  };
  return (
    <>
      {/*     <div className="day_calendar container">
        <span className="day_timings col-lg-2 col-md-3 col-3">Timings</span>
        <div className="day_space week_date col-lg-10 col-md-9 col-9">
          <div className="dayDate">
            <span>
              {new Date(date._d == undefined ? new Date() : date._d)
                .toDateString()
                .substring(4, 15)}
            </span>
          </div>
        </div> 
        </div>*/}
      {weekData.timings.map((timings, index) => {
        return (
          <div className="day_calendar container " key={index}>
            <span className="day_timings col-lg-2 col-md-3 col-3">
              {timings}
            </span>
            <div
              className={`day_space col-lg-10 col-md-9 col-9 ${check(
                new Date(date._d).toDateString() + " " + timings
              )}`}
              onClick={() => handleOnClick(index, timings)}
            >
              {bookedSlotInfo(new Date(date._d).toDateString() + " " + timings)}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default DayCalendar;
