import {vi} from "date-fns/esm/locale";
import React from "react";
import ClassOne from "./Classes/ClassOne";

const Content = ({courseName, classNo}) => {
  const renderContent = () => {
    let view;
    switch (classNo) {
      case "Class 1":
        view = <ClassOne courseName={courseName} classNo={classNo} />;
        break;
      default:
        view = <h1>No Content Yet</h1>;
    }
    return view;
  };

  return <>{renderContent()}</>;
};

export default Content;
