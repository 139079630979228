import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import BackNavigation from "../../../../Common/Components/BackNavigation";
import Base from "../../../../Common/Components/Base";
import TkButton from "../../../../Common/Components/TkButton";
import "./TicketStyles.css";
import { UserContext } from "../../../../App";
import { getTickets } from "./TicketAPIcalls";

const TicketSupport = () => {
  const history = useHistory();
  const { token } = useContext(UserContext);
  const [tickets, setTicket] = useState([]);
  useEffect(() => {
    getTickets(token).then((res) => {
      setTicket(res);
    });
  }, []);
  return (
    <Base>
      <div className="w_85 mx-auto">
        <BackNavigation title={"Your Ticket"} color={"#000"} />
        <div className="ticket_section">
          <div className="ticket_top">
            <h1>Ticket Details</h1>
          </div>
          <div className="ticket_info">
            <h1>Existing Ticket</h1>

            <table className="table ticket_table">
              <thead>
                <td>Sr no</td>
                <td>Ticket no</td>
                <td>Category</td>
                <td>Progress</td>
              </thead>
              {tickets.map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.ticketNumber}</td>
                    <td>{item.category}</td>
                    <td>{item.progress}</td>
                  </tr>
                );
              })}
            </table>
            <TkButton
              name={"New Ticket"}
              style={{ backgroundColor: "#6b0080" }}
              _onBtnClick={() => history.push("/setting/create/ticket")}
            />
          </div>
        </div>
      </div>
    </Base>
  );
};

export default TicketSupport;
