import React, { useState, useContext, useRef } from "react";
import Base from "../../Common/Components/Base";
import "./InviteWin";
import ReferralBanner from "../../Assets/Image/refferal.png";
import wp from "../../Assets/Image/whatsapp.png";
import twitter from "../../Assets/Image/twitter.png";
import fb from "../../Assets/Image/facebook.png";
import refInfo from "../../Assets/Image/referralInfo.png";
import { IoMdCopy } from "react-icons/io";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import "./InviteWin.css";
import { UserContext } from "../../App";
import { faqData, tncData } from "./data";
import StatusModal from "../../Common/Components/StatusModal";
import MyRefferals from "./components/MyRefferals";
const InviteWin = () => {
  const [tnc, setTnc] = useState(false);
  const [faq, setFaq] = useState(false);
  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: "",
    statusText: "",
  });
  const { userData, token } = useContext(UserContext);
  const couponCode = "kk34kmhaespr";
  let inviteUrl =
    "https://techokids.com/?referral=" + userData.uid + "&By=" + userData.name;
  const link = useRef(null);
  function copyText() {
    navigator.clipboard.writeText(link.current.innerText);
    setStatusModal({
      ...statusModal,
      status: "success",
      showStatusModal: true,
      statusText: "Copied Successfully",
    });
  }

  function shareOnFB() {
    var url = `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftechokids.com%2F%3Freferral%3D+${userData.uid}+%26By%3D+${userData.name}`;
    window.open(
      url,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
    );
    return false;
  }

  function shareOntwitter() {
    var url = `https://twitter.com/intent/tweet?text=Hi%21+I+invite+you+to+join+TechoKids%2C+%231+Best+live+online+Coding+%26+Robotics+platform+for+kids%21+Get+flat+12%25+off+on+purchasing+any+plan+and+book+A+free+Trial+using+my+referral+code+${userData.uid}+.+Check+it+out+here+-+https%3A%2F%2Ftechokids.com%2F%3Freferral%3D${userData.uid}%26By%3D${userData.name}`;
    window.open(url, "TwitterWindow");
    return false;
  }

  function shareOnWhatsup() {
    var url = `https://wa.me/?text=Hi%21+I+invite+you+to+join+TechoKids%2C+%231+Best+live+online+Coding+%26+Robotics+platform+for+kids%21+Get+flat+12%25+off+on+purchasing+any+plan+and+book+A+free+Trial+using+my+referral+code+%2A${userData.uid}%2A+.+Check+it+out+here+-+https%3A%2F%2Ftechokids.com%2F%3Freferral%3D${userData.uid}%26By%3D${userData.name}`;
    window.open(
      url,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=350,width=480"
    );
    return false;
  }

  // const handleWhatsup = () => {
  //   ;<a
  //     href='javascript:void(0);'
  //     class='ss-lk facebook ttp'
  //     onclick="shareOpen('https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.ndtv.com%2Fworld-news%2Fboris-johnson-backs-astrazeneca-made-in-india-us-uk-used-world-over-2391994&amp;t=%22AstraZeneca+Safe%2C+Made+In+India%2C+US%2C+UK%2C+Used+World+Over%22%3A+Boris+Johnson', 900, 500);"
  //   ></a>
  // }
  return (
    <Base>
      <div className="referral w_85 mx-auto">
        <img src={ReferralBanner} alt="" className="img-fluid" />
        <div className="referral_info">
          <h1 className="referral_title">Invite & Win</h1>
          <span className="referral_exp">
            Share with your personalised link
          </span>

          <div className="social_icon">
            <a onClick={() => shareOnWhatsup()}>
              {" "}
              <img src={wp} alt="Hello" className="img-fluid" />
            </a>
            <a onClick={() => shareOntwitter()}>
              {" "}
              <img src={twitter} alt="" className="img-fluid" />
            </a>
            <a onClick={() => shareOnFB()}>
              {" "}
              <img src={fb} alt="" className="img-fluid" />
            </a>
          </div>
          <div className="referral_link_conrainer">
            <div className="link" ref={link}>
              {inviteUrl}
            </div>
            <div className="copy_btn" onClick={() => copyText()}>
              <IoMdCopy color={"#fff"} size={20} />
            </div>{" "}
          </div>
          <img src={refInfo} alt="" className="img-fluid mt-5" />
        </div>
        <MyRefferals token={token} userData={userData} />
        <div className="table_div">
          <table className="referral_table table ">
            <tr>
              <th colspan="3">Affiliate Program</th>
            </tr>
            <tr>
              <th>No. of Student Enrolled</th>
              <th>Per Student</th>
              <th>Reward</th>
            </tr>
            <tr>
              <td>1</td>
              <td>&#8377;1000</td>
              <td>&#8377;1000</td>
            </tr>
            <tr>
              <td>2</td>
              <td>&#8377;1000</td>
              <td>&#8377;2000</td>
            </tr>
            <tr>
              <td>3</td>
              <td>&#8377;2000</td>
              <td>&#8377;6000</td>
            </tr>
            <tr>
              <td>4</td>
              <td>&#8377;2000</td>
              <td>&#8377;8000</td>
            </tr>
            <tr>
              <td>5</td>
              <td>&#8377;3000</td>
              <td>&#8377;15000</td>
            </tr>
            <tr>
              <td>6</td>
              <td>&#8377;3000</td>
              <td>&#8377;18000</td>
            </tr>
            <tr>
              <td>7</td>
              <td>&#8377;3000</td>
              <td>&#8377;21000</td>
            </tr>
            <tr>
              <td>8</td>
              <td>&#8377;4000</td>
              <td>&#8377;32000</td>
            </tr>
            <tr>
              <td>9</td>
              <td>&#8377;4000</td>
              <td>&#8377;36000</td>
            </tr>
            <tr>
              <td>10</td>
              <td>&#8377;5000</td>
              <td>&#8377;50000</td>
            </tr>
            <tr>
              <td>Above 10</td>
              <td></td>
              <td>MacBook</td>
            </tr>
          </table>
        </div>
        <div className="info_text">
          <div className="sh_bg">
            <div className="tnc d-flex justify-content-between align-items-center">
              <span className="f-m">Terms and Conditions</span>
              <span onClick={() => setTnc(!tnc)}>
                {tnc ? (
                  <RiArrowDropUpLine size={40} />
                ) : (
                  <RiArrowDropDownLine size={40} />
                )}
              </span>
            </div>
            <ol>
              {tnc &&
                tncData.map((item, index) => {
                  return (
                    <li className="mt-2">
                      <span> {item}</span>
                    </li>
                  );
                })}
            </ol>
          </div>
          <div className="sh_bg mt-5">
            <div className="faq   d-flex justify-content-between align-items-center">
              <span className="f-m">FAQs & Strategies for "Refer & Earn"</span>
              <span onClick={() => setFaq(!faq)}>
                {faq ? (
                  <RiArrowDropUpLine size={40} />
                ) : (
                  <RiArrowDropDownLine size={40} />
                )}
              </span>
            </div>
            <ol>
              {faq &&
                faqData.map((item, index) => {
                  return (
                    <li>
                      <b>{item.q}</b>
                      <br />
                      <span>{item.ans}</span>
                    </li>
                  );
                })}
            </ol>
          </div>
        </div>
        <StatusModal
          showModal={statusModal.showStatusModal}
          text={statusModal.statusText}
          status={statusModal.status}
          closeModal={() =>
            setStatusModal({ ...statusModal, showStatusModal: false })
          }
        />
      </div>
    </Base>
  );
};

export default InviteWin;
