import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import { UserContext } from "../../App";
import Base from "../../Common/Components/Base";
import "./Leave.css";
import DayPicker from "./DayPicker";
import TkDropDown from "../../Common/Components/DropDown";
import BackNavigation from "../../Common/Components/BackNavigation";
import TkButton from "../../Common/Components/TkButton";
import StatusModal from "../../Common/Components/StatusModal";
import { createLeave } from "./LeaveAPIcalls";
const LeaveApplication = () => {
  const [typeDropdown, settypeDropdown] = useState("Emergency Leave");
  const [reason, setReason] = useState("Other");
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [description, setDescription] = useState("");
  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: "",
    statusText: "",
  });
  let history = useHistory();
  const { token, userData } = useContext(UserContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(reason, from, to, description, typeDropdown);
    if (!reason || !from || !to || !description || !typeDropdown) {
      setStatusModal({
        ...statusModal,
        showStatusModal: true,
        status: "error",
        statusText: "Enter All the Fields",
      });
      return;
    }
    const data = {
      type: typeDropdown,
      from: from,
      to: to,
      reason: reason,
      description: description,
      name: userData.name,
    };
    createLeave(data, token).then((res) => {
      if (res.error) {
        console.log(res.error);
        setStatusModal({
          ...statusModal,
          showStatusModal: true,
          status: "error",
          statusText: res.error,
        });
        return;
      } else {
        setDescription("");
        setFrom(new Date());
        setTo(new Date());
        setReason("Other");
        settypeDropdown("Emergency Leave");
        setStatusModal({
          ...statusModal,
          showStatusModal: true,
          status: "success",
          statusText: "Leave Created Successfully",
        });
      }
    });
  };

  return (
    <Base>
      <div className="leave w_90 mx-auto">
        <BackNavigation title={"Leave Request"} color={"#000"} />

        <form>
          <div className="form-group">
            <TkDropDown
              label="Leave Types"
              _onClick={settypeDropdown}
              value={typeDropdown}
              items={[
                "Emergency Leave",
                "Regular Leave",
                "Sick Leave",
                "Casual Leave",
                "Leave Without Pay",
                "Religious Leave",
                "Maternity Leave",
                "Other",
              ]}
            />
          </div>

          <div className="form-group leave_range">
            <DayPicker label="Applying from:" date={from} setDate={setFrom} />
            <DayPicker label="Applying to:" date={to} setDate={setTo} />
          </div>

          <div className="form-group">
            <TkDropDown
              label="Reason"
              value={reason}
              _onClick={setReason}
              items={[
                "Electricity Issue",
                "Regular Leave",
                "Personal issue",
                "Laptop not working",
                "Internet Issue",
                "Other",
              ]}
            />
          </div>
          <div className="form-group leave_desc">
            <label>Description</label>
            <textarea
              rows="10"
              className="leave_desc_input"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
          <TkButton
            name={"Apply Leave"}
            style={{ backgroundColor: "#00B23B" }}
            _onBtnClick={(e) => handleSubmit(e)}
          />
        </form>
        <StatusModal
          showModal={statusModal.showStatusModal}
          text={statusModal.statusText}
          status={statusModal.status}
          closeModal={() => {
            statusModal.status === "error"
              ? setStatusModal({ ...statusModal, showStatusModal: false })
              : history.goBack();
          }}
        />
      </div>
    </Base>
  );
};

export default LeaveApplication;
