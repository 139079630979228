import React, { useState, useEffect } from "react";
import "./MyRefferals.css";
import { base_route } from "../../../backend";
import successReferalImg from "../../../Assets/invite/success.png";
import prizeReferalImg from "../../../Assets/invite/prize.png";
import pendingReferalImg from "../../../Assets/invite/pending.png";
const MyRefferals = ({ token, userData }) => {
    const [users, setUsers] = useState([
        { booktrial: false, enrolled: false, trialcomplete: false },
    ]);
    const [myref, setmyRef] = useState([{}]);
    useEffect(async () => {
        await fetch(`${base_route}/invite/myInvites`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bearer-token": `${token}`,
            },
        })
            .then((res) => res.json())
            .then((resp) => {
                console.log("rsp at tref", resp);
                setUsers(resp);
            })
            .catch((err) => console.log(err));
    }, [token]);

    useEffect(() => {
        if (userData != undefined) {
            console.log(userData.myReferrals);
            setmyRef(userData.myReferrals);
        }
    }, [userData]);

    const sendRequest = async (ruid) => {
        await fetch(`${base_route}/invite/sentrequest`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bearer-token": `${token}`,
            },
            body: JSON.stringify({ profile: "teacher", ruid }),
        })
            .then((res) => res.json())
            .then((resp) => {
                console.log(resp);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const showReward = (index, item) => {
        var prize = [
            1000,
            2000,
            6000,
            8000,
            15000,
            18000,
            21000,
            32000,
            50000,
            "Mac Book",
        ];
        var reward = prize[index];
        //console.log("refferal item", item, myref);
        var found = {
            requestReject: false,
            requestReject: false,
            requestAccept: false,
        };
        if (myref != undefined) {
            console.log("hello");
            for (var i = 0; i < myref.length; i++) {
                if (myref[i].uid == item.uid) {
                    found = myref[i];
                    break;
                }
            }
        }
        if (item.trialcomplete && item.enrolled) {
            if (found.requestSent === false) {
                return (
                    <div className="referal__complete">
                        <div className="referal__completeContainer">
                            <div className="status_icons">
                                <img src={prizeReferalImg} alt="rewardImage" />
                            </div>
                            <p>{`you’ve got ₹${reward} reward`}</p>
                        </div>
                        <button
                            className="referal__complete__redeem__btn"
                            onClick={() => sendRequest(item.uid)}
                        >
                            Redeem Now
                        </button>
                    </div>
                );
            } else if (
                found.requestAccept === false &&
                found.requestReject === false
            ) {
                return (
                    <div className="referal__complete">
                        <div className="referal__completeContainer">
                            <div className="status_icons">
                                <img src={prizeReferalImg} alt="rewardImage" />
                            </div>
                            <p>{`you’ve got ₹${reward} reward`}</p>
                        </div>
                        <button className="referal__complete__redeem__btn">
                            Request Sent
                        </button>
                    </div>
                );
            } else if (
                found.requestAccept === false &&
                found.requestReject === true
            ) {
                return (
                    <div className="referal__complete">
                        <div className="referal__completeContainer">
                            <div className="status_icons">
                                <img src={prizeReferalImg} alt="rewardImage" />
                            </div>
                            <p>{`you’ve got ₹${reward} reward`}</p>
                        </div>
                        <button className="referal__complete__redeem__btn">
                            Rejected
                        </button>
                    </div>
                );
            } else {
                return (
                    <div className="referal__complete">
                        <div className="referal__completeContainer">
                            <div className="status_icons">
                                <img src={prizeReferalImg} alt="rewardImage" />
                            </div>
                            <p>{`you’ve got ₹${reward} reward`}</p>
                        </div>
                        <button className="referal__complete__redeem__btn">
                            Redeemed
                        </button>
                    </div>
                );
            }
        } else {
            <></>;
        }
    };
    const isCompleted = (item) => {
        if (item.trialcomplete && item.enrolled) {
            return "MyReferals__completed";
        } else {
            return "";
        }
    };
    return (
        <div className="MyRefferals mb-5">
            <h3 className="MyRefferals__heading mb-2 ">My Referrals</h3>
            <div className="MyReferals__table">
                <div className="table_header table_body_items MyReferals__table--row ">
                    <div className="tabel_items">Profile Name</div>
                    <div className="tabel_items">Book Trial</div>
                    <div className="tabel_items">Trial Done</div>
                    <div className="tabel_items">Enrolled</div>
                </div>
                {users.map((item, index) => {
                    return (
                        <div
                            className={`table_body MyReferals__table--row ${isCompleted(
                                item
                            )}`}
                        >
                            <div className="table_body_items">
                                <div className="tabel_items">{item.name}</div>
                                <div className="tabel_items status_icons">
                                    <img
                                        src={successReferalImg}
                                        alt="successreferal"
                                    />
                                </div>
                                <div className="tabel_items status_icons">
                                    <img
                                        src={
                                            item.trialcomplete
                                                ? successReferalImg
                                                : pendingReferalImg
                                        }
                                        alt="successreferal"
                                    />
                                </div>
                                <div className="tabel_items status_icons">
                                    <img
                                        src={
                                            item.enrolled
                                                ? successReferalImg
                                                : pendingReferalImg
                                        }
                                        alt="successreferal"
                                    />
                                </div>
                            </div>
                            {showReward(index, item)}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default MyRefferals;
