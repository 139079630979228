import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import BackNavigation from "../../../Common/Components/BackNavigation";
import Base from "../../../Common/Components/Base";
import InfoBox from "../../../Common/Components/InfoBox";
import TkButton from "../../../Common/Components/TkButton";
import "./components.css";

const CourseDetailList = ({_onClick, data}) => {
  const history = useHistory();
  const location = useLocation();
  let courseName = location.state.courseName;

  const onCourseClick = (classNo) => {
    history.push({
      pathname: `/courses/${courseName}/${classNo}`,
      state: {courseName: courseName, classNo: classNo},
    });
  };

  return (
    <Base>
      <div className="course_detail">
        <span className="detail_back">
          <BackNavigation />
        </span>
        <div className="detail_container d-flex align-items-center">
          <div className="course_details_container">
            <span className="course_title">{courseName}</span>
            <InfoBox
              name={"Class 1"}
              subName={"Small Description"}
              renderIcon={
                <TkButton
                  name={"View"}
                  style={{backgroundColor: "#00B23B", width: "10%"}}
                  _onBtnClick={() => onCourseClick("class 1")}
                />
              }
            />
            <InfoBox
              name={"Class 2"}
              subName={"Small Description"}
              renderIcon={
                <TkButton
                  name={"View"}
                  style={{backgroundColor: "#00B23B", width: "10%"}}
                  _onBtnClick={() => onCourseClick("class 2")}
                />
              }
            />
            <InfoBox
              name={"Class 3"}
              subName={"Small Description"}
              renderIcon={
                <TkButton
                  name={"View"}
                  style={{backgroundColor: "#00B23B", width: "10%"}}
                  _onBtnClick={() => onCourseClick("class 3")}
                />
              }
            />
            <InfoBox
              name={"Class 4"}
              subName={"Small Description"}
              renderIcon={
                <TkButton
                  name={"View"}
                  style={{backgroundColor: "#00B23B", width: "10%"}}
                  _onBtnClick={() => onCourseClick("class 4")}
                />
              }
            />
            <InfoBox
              name={"Class 5"}
              subName={"Small Description"}
              renderIcon={
                <TkButton
                  name={"View"}
                  style={{backgroundColor: "#00B23B", width: "10%"}}
                  _onBtnClick={() => onCourseClick("class 5")}
                />
              }
            />
          </div>
        </div>
      </div>
    </Base>
  );
};

export default CourseDetailList;
