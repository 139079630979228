import React from "react";
import "./Components.css";
import { AiOutlineRightCircle, AiOutlineLeftCircle } from "react-icons/ai";

const CalendarHeader = ({
  value,
  setValue,
  dropDownChange,
  dropDownValue,
  setScheduledDate,
}) => {
  const currMonthName = () => {
    return value.format("MMMM");
  };
  const showHeader = () => {
    if (dropDownValue == "Month") {
      return currMonthName();
    } else if (dropDownValue == "Week") {
      var Ndate =
        new Date(value.clone().add(1, "week")).getTime() - 24 * 60 * 60 * 1000;
      var ndate = new Date(Ndate).toDateString().slice(4);
      var Pdate =
        new Date(value.clone().subtract(1, "week")).getTime() +
        7 * 24 * 60 * 60 * 1000;
      var pdate = new Date(Pdate).toDateString().slice(4);
      return pdate + " - " + ndate;
    } else {
      var Pdate =
        new Date(value.clone().subtract(1, "week")).getTime() +
        7 * 24 * 60 * 60 * 1000;
      var pdate = new Date(Pdate).toDateString().slice(4);
      return pdate;
    }
  };

  const showYear = () => {
    if (dropDownValue == "Month") return currYear();
    else {
      return "";
    }
  };

  const currYear = () => {
    return value.format("YYYY");
  };

  const prevMonth = () => {
    return value.clone().subtract(1, "month");
  };
  const nextMonth = () => {
    return value.clone().add(1, "month");
  };
  const thisMonth = () => {
    return value.isSame(new Date(), "month");
  };
  const goNext = () => {
    if (dropDownValue == "Month") {
      return value.clone().add(1, "month");
    } else if (dropDownValue == "Week") {
      console.log("Week", value.clone().add(1, "week"));
      return value.clone().add(1, "week");
    } else {
      //day
      setScheduledDate(value.clone().add(1, "day"));
      return value.clone().add(1, "day");
    }
  };
  const goBack = () => {
    console.log("Value", dropDownValue);
    if (dropDownValue == "Month") {
      return value.clone().subtract(1, "month");
    } else if (dropDownValue == "Week") {
      return value.clone().subtract(1, "week");
    } else {
      //day
      setScheduledDate(value.clone().subtract(1, "day"));
      return value.clone().subtract(1, "day");
    }
  };
  return (
    <div className="header">
      <div className="header_month">
        <div className="month_icon" onClick={() => setValue(goBack())}>
          <AiOutlineLeftCircle />
        </div>

        <div className="current_month">
          {showHeader()}
          {showYear()}
        </div>
        <div className="month_icon" onClick={() => setValue(goNext())}>
          {<AiOutlineRightCircle />}
        </div>
      </div>
      <div className="header_dropdown">
        <select
          className="select_calander_type"
          value={dropDownValue}
          onChange={(event) => dropDownChange(event.target.value)}
        >
          <option className="calander_item">Month</option>
          <option className="calander_item">Week</option>
          <option className="calander_item">Day</option>
        </select>
      </div>
    </div>
  );
};

export default CalendarHeader;
