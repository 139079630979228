import React from "react";
import {useHistory} from "react-router-dom";
import {AiOutlineArrowLeft} from "react-icons/ai";
import "./commonComponents.css";

const BackNavigation = ({title, color, url}) => {
  const history = useHistory();

  return (
    <div className="d-flex align-items-center mb-3">
      <AiOutlineArrowLeft
        size={30}
        color="#6b0080"
        onClick={() => {
          url ? history.push(url) : history.goBack();
        }}
      />
      <span className="back_title" style={{color: color}}>
        {title}
      </span>
    </div>
  );
};

export default BackNavigation;
