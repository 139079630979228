import React, { useState, useContext } from "react";
import Base from "../../Common/Components/Base";
import "./courses.css";
import { ReactComponent as LevelOne } from "../../Assets/Image/levelone.svg";
import { ReactComponent as LevelTwo } from "../../Assets/Image/leveltwo.svg";
import { ReactComponent as LevelThree } from "../../Assets/Image/levelthree.svg";
import { CoursesData } from "./coursesData";
import { UserContext } from "../../App";
import CourseDetail from "./Components/CourseDetailList";
import { Link, Redirect, useHistory } from "react-router-dom";
import StatusModal from "../../Common/Components/StatusModal";

const Courses = () => {
  const [levelActive, setlevelActive] = useState("level_1");
  const [showDetail, setShowDetail] = useState(false);
  const [courseData, setCourseData] = useState(null);
  const { userData, token, setProfileInfo, setLoading } = useContext(
    UserContext
  );
  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: "",
    statusText: "",
  });
  const history = useHistory();
  const handleOnClick = (data) => {
    var selected = data.name;
    if (!userData.myCourses.includes(data.name)) {
      setStatusModal({
        ...statusModal,
        status: "error",
        showStatusModal: true,
        statusText: "YOU ARE NOT ALLOWED",
      });
      return;
    }
    history.push({
      pathname: `/courses/${data.name}`,
      state: { courseName: data.name },
    });
  };
  return (
    <Base>
      {showDetail ? (
        <CourseDetail _onClick={() => setShowDetail(false)} data={courseData} />
      ) : (
        <div className="course_page w_85 mx-auto">
          <span className="course_page_title">Curriculum</span>
          <div className="course_level ">
            <div
              className={`courses_level ${
                levelActive === "level_1" && "level_active"
              }`}
              onClick={() => setlevelActive("level_1")}
            >
              <span className="level_text">Level</span>
              <div className="courses_icon">
                <LevelOne />
              </div>
            </div>
            <div
              className={`courses_level ${
                levelActive === "level_2" && "level_active"
              }`}
              onClick={() => setlevelActive("level_2")}
            >
              <span className="level_text">Level</span>
              <div className="courses_icon">
                <LevelTwo />
              </div>
            </div>
            <div
              className={`courses_level ${
                levelActive === "level_3" && "level_active"
              }`}
              onClick={() => setlevelActive("level_3")}
            >
              <span className="level_text">Level</span>
              <div className="courses_icon">
                <LevelThree />
              </div>
            </div>
          </div>
          <div className="course_container">
            {CoursesData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="course_info"
                  onClick={() => {
                    handleOnClick(item);
                  }}
                >
                  <img src={item.icon} alt="" className="course_icon" />
                  <span className="course_name">{item.name}</span>
                </div>
              );
            })}
          </div>
          <StatusModal
            showModal={statusModal.showStatusModal}
            text={statusModal.statusText}
            status={statusModal.status}
            closeModal={() =>
              setStatusModal({ ...statusModal, showStatusModal: false })
            }
          />
        </div>
      )}
    </Base>
  );
};

export default Courses;
