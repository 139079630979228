import { base_route } from "../../backend";
import axios from "axios";

axios.defaults.baseURL = base_route;
// makes an API request to verify OTP
export const verifyOTP = function (email, otp, token) {
  let payload = { email, otp, token };

  return new Promise((resolve, reject) => {
    axios
      .post("/auth/teacher/verify", payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loginWithOTP = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/auth/teacher/send`, { email })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err));
  });
};

export const resetPasswordOTP = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/auth/teacher/resetpassword`, { email })
      .then((response) => {
        console.log("response of login with OTP ", response);
        resolve(response);
      })
      .catch((err) => reject(err));
  });
};

export const loginWithPassword = (email, password) => {
  return fetch(`${base_route}/auth/teacher/signin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const getProfile = (token) => {
  return fetch(`${base_route}/dashboard/teacher/getProfile`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
export const resetPassword = (newPassword, token, otp) => {
  console.log(newPassword, token);
  return fetch(`${base_route}/auth/teacher/resetpassword/verify`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token, newPassword, otp }),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const authenticate = (data, next) => {
  if (typeof window != "undefined") {
    localStorage.setItem("tk_jwt", JSON.stringify(data));
    next();
  }
};
export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("tk_jwt")) {
    return JSON.parse(localStorage.getItem("tk_jwt"));
  } else {
    return false;
  }
};
export const logout = (next) => {
  if (typeof window != "undefined") {
    localStorage.removeItem("tk_jwt");
    // localStorage.removeItem("profile-url");
    next();
  }
};
