import { base_route } from "../../backend";

export const addSeen = (update, email) => {
  return fetch(`${base_route}/notification/addseen`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ update, email }),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
export const setPassword = (password, token) => {
  return fetch(`${base_route}/register/teacher/setpassword`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
    body: JSON.stringify({ password }),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
export const updateClassStatus = (token, status, classID) => {
  return fetch(`${base_route}/slot/updateClassStatus`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
    body: JSON.stringify({ classID, status }),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addPaidFeedback = (token, feedback, ID) => {
  return fetch(`${base_route}/slot/addpaidfeedback`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
    body: JSON.stringify({
      feedback,
      ID,
    }),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addFeedback = (
  token,
  feedback,
  chanceOfBuy,
  chanceOfBuy2,
  notPosted,
  rating,
  reason,
  ID
) => {
  return fetch(`${base_route}/slot/addfeedback`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
    body: JSON.stringify({
      feedback,
      chanceOfBuy,
      chanceOfBuy2,
      notPosted,
      rating,
      reason,
      ID,
    }),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getProfile = (token) => {
  return fetch(`${base_route}/dashboard/teacher/getProfile`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
export const getNoti = () => {
  return fetch(`${base_route}/notification/get`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const getSchedule = (token) => {
  return fetch(`${base_route}/slot/upcoming`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const getTrialTaken = (token) => {
  return fetch(`${base_route}/slot/trialtaken`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
export const getTrialConverted = (token) => {
  return fetch(`${base_route}/slot/trialconverted`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
export const getPanalty = (token) => {
  return fetch(`${base_route}/slot/trailcancelled`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
export const getPaidClasses = (token) => {
  return fetch(`${base_route}/slot/paidclass`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
export const getTrialCancelled = (token) => {
  return fetch(`${base_route}/slot/trailcancelled`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const getDashboardData = (token, date) => {
  return fetch(`${base_route}/dashboard/teacher/myclassbydate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "bearer-token": `${token}`,
    },
    body: JSON.stringify({ date }),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
