import "./components.css";
import React, { useState, useContext, useEffect } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import "./components.css";

import ClassDetail from "../../../Common/Components/ClassDetail";
import MonthPicker from "../../../Common/Components/MonthPicker";
import BackNavigation from "../../../Common/Components/BackNavigation";
import Base from "../../../Common/Components/Base";
import { UserContext } from "../../../App";
import { getTrialConverted } from "../DashboardAPICalls.js";

const TrialConverted = () => {
  const [data, setData] = useState([{ name: "", subName: "", status: "" }]);
  const [date, setDate] = useState(new Date());
  const { userData, token, setProfileInfo, setLoading } = useContext(
    UserContext
  );
  useEffect(async () => {
    await getTrialConverted(token).then((data) => {
      setData(data);
    });
  }, []);
  return (
    <Base>
      <div className="trial_taken_view w_85 mx-auto">
        <BackNavigation title={"Trial Converted"} color={"#000"} />

        <MonthPicker className="my-5" date={date} setDate={setDate} />

        <span className="dashboard_title ">Converted</span>
        {data.map((item) => {
          var slotyear = new Date(item.slot).getFullYear();
          var selectedyear = new Date(date).getFullYear();
          var slotmonth = new Date(item.slot).getMonth();
          var selectedmonth = new Date(date).getMonth();
          if (slotyear == selectedyear && slotmonth == selectedmonth) {
            return (
              <ClassDetail
                name={item.studentName}
                subName={item.remark}
                classTime={item.slot}
                grade={item.grade}
              />
            );
          }
        })}
      </div>
    </Base>
  );
};

export default TrialConverted;
