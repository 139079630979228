import React, { useState, useEffect } from "react";
import buildCalandar from "./helper.js";
import CalendarHeader from "./CalendarHeader";
import "./Components.css";
import moment from "moment";
const Calendar = ({ value, onChange }) => {
  const [calandar, setCalandar] = useState([]);
  useEffect(() => {
    setCalandar(buildCalandar(value));
  }, [value]);

  const startOfMonth = value.clone().startOf("month");
  const endOfMonth = value.clone().endOf("month");
  const cloneDay = moment().clone();
  const isSelected = (day) => {
    return value.isSame(day, "day");
  };

  const beforeToday = (day) => {
    var ms = new Date().getTime() + 48 * 60 * 60 * 1000;
    var tomorrow = new Date(ms);
    return day.isBefore(tomorrow, "day");
  };
  const isToday = (day) => {
    var ms = new Date().getTime() + 48 * 60 * 60 * 1000;
    var tomorrow = new Date(ms);
    return day.isSame(tomorrow, "day");
  };

  const isNotInMonth = (day) => {
    let _afterDate = cloneDay.clone().add(31, "day");
    return (
      day.isBefore(startOfMonth, "day") ||
      day.isAfter(endOfMonth, "day") ||
      day.isAfter(_afterDate, "day")
    );
  };

  const dayStyles = (day) => {
    if (isToday(day)) return "today";
    if (beforeToday(day)) return "before";
    if (isSelected(day)) return "selected";
    return "";
  };

  const tdStyles = (day) => {
    if (isNotInMonth(day)) return "not_in_month";
    return "";
  };
  const isAfter30day = (afterDate) => {
    let _afterDate = cloneDay.clone().add(31, "day");
    if (afterDate.isAfter(_afterDate, "day")) return "before";
  };

  return (
    <div className="calendar">
      <table className="body table table-bordered">
        <thead className="day_names ">
          <tr>
            {["THU", "FRI", "SAT", "SUN", "MON", "TUE", "WED"].map((d) => (
              <th className="week">{d}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {calandar.map((week) => {
            return (
              <tr>
                {week.map((day) => (
                  <td
                    className={`day ${tdStyles(day)} ${isAfter30day(
                      day
                    )} ${dayStyles(day)}`}
                    onClick={() => !isNotInMonth(day) && onChange(day)}
                  >
                    <span>{day.format("D").toString()}</span>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Calendar;
