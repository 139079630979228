import React, { useState } from 'react'
import Base from '../../Common/Components/Base'
import { TopicData } from './data'
import './Handbook.css'
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai'
import HandBookInfo from './HandBookInfo'
import TkButton from '../../Common/Components/TkButton'
import { Link } from 'react-router-dom'

function Handbook() {
  const [currIndex, setcurrIndex] = useState(0)
  const [handbookCurrIndex, sethandbookCurrIndex] = useState(null)
  const [expand, setExpand] = useState(false)

  const handleOnTopicClick = (index) => {
    setcurrIndex(index)
  }

  const isActive = (index) => {
    return index === currIndex && 'handbook_active'
  }
  return (
    <Base>
      <div className='handbook_container '>
        <div className=' handbook_topic_section'>
          <ul className='handbook_topic_list'>
            {/* {TopicData.map((item, index) => {
              return (
                <li
                  className={`handbook_topic ${isActive(index)}`}
                  onClick={() => handleOnTopicClick(index)}
                >
                  {item.topic}
                </li>
              )
            })} */}

            <li
              className={`handbook_topic ${isActive(0)}`}
              onClick={() => handleOnTopicClick(0)}
            >
              Human Resources
            </li>
            <li
              className={`handbook_topic ${isActive(1)}`}
              onClick={() => handleOnTopicClick(1)}
            >
              Demo Classroom Protocol
            </li>
            <li
              className={`handbook_topic ${isActive(2)}`}
              onClick={() => handleOnTopicClick(2)}
            >
              Paid Classroom Protocol
            </li>
          </ul>
        </div>
        
          <div className='handbook_desc_container'>
            {/* <h1>{TopicData[currIndex].topic}</h1>
            <ul>
              {TopicData[currIndex].desc.map((item, index) => {
                return (
                  <li key={index}>
                    <HandBookInfo info={item} />
                  </li>
                )
              })}
            </ul> */}
            {currIndex === 0 ? (
              <div>
                <h1>Human Resources</h1>
                <h5>Commitment</h5>
                <ol>
                  <li>
                    <span>
                      The minimum time duration you need to be with us is 3
                      months for part-time internship/Job.
                    </span>
                  </li>
                  <li>
                    <span>
                      You need to give a notice of 1 month if you want to end
                      your association with TechoKids.
                      <br />
                      <br />
                      <i>
                        Note : This is applicable only when you want to end your
                        internship after completion of 3 months or during your
                        internship period.
                      </i>
                    </span>
                  </li>
                  <li>
                    <span>
                      You would have to provide the minimum hour commitment as
                      below: <br />- Saturday & Sunday : Minimum availability of
                      4-5 hours per day <br />
                      - Remaining 4 weekdays : Minimum availability of 3- 4
                      hours per day
                      <br />
                      <br />
                      <i>
                        Note :There would be a week off on Wednesday (Note : Any
                        change in this would be informed to you by your manager)
                      </i>
                    </span>
                  </li>
                  <li>
                    <span>
                      You will get a certificate of experience after you
                      complete your internship duration of minimum 3 months and
                      end your association with TechoKids.
                    </span>
                  </li>
                  <li>
                    <span>
                      During the first 15 days of your work you will be
                      considered under probation. If not performed well, you
                      might be asked to part ways with the company.
                    </span>
                  </li>
                  <li>
                    <span>
                      The payment would be done by the 10th of each month Eg:
                      You will receive the payment for January by the 10th of
                      February.
                    </span>
                  </li>
                  <li>
                    <span>
                      Working hours : <br />
                      - Weekdays : From 11 a.m. to 8 p.m. <br />- Weekends :
                      From 10 a.m. to 8 p.m.
                    </span>
                  </li>
                  <li>
                    <span>
                      Please refer to the payment policy here{' '}
                      <a href='https://drive.google.com/file/d/1xlZWQfHf3CBByJdHIMBn8lHyvz0WNCQ3/view?usp=sharing'>
                        Payment Policy
                      </a>
                    </span>
                  </li>
                </ol>
              </div>
            ) : null}

            {currIndex === 1 ? (
              <div>
                <h1>Demo Classroom Protocol</h1>
                <h5>Beginning</h5>
                <ol>
                  <li>
                    <span>
                      Join 5-7 minute prior to the class starts to set up your
                      internet, mic, webcam etc.
                    </span>
                  </li>
                  <li>
                    <span>
                      Sit in a brightly lit place and make sure you look
                      professional.
                    </span>
                  </li>
                  <li>
                    <span>
                      There should be no potentially disturbing things in your
                      background like a pet or people talking.
                    </span>
                  </li>
                  <li>
                    <span>
                      Please put your phones on silent before you start the
                      class.
                    </span>
                  </li>
                  <li>
                    <span>
                      If the student doesn’t join, call the concerned person in
                      the next 5 min. If the student does not join within the
                      first 20 minutes, you can leave.
                    </span>
                  </li>
                  <li>
                    <span>Internet speed of 15 Mbps or more is necessary</span>
                  </li>
                </ol>
                <h5>During</h5>
                <ol>
                  <li>
                    <span>
                      Keep your camera on during the whole duration of the class
                      so that it is easy for the kid to connect to you.
                    </span>
                  </li>
                  <li>
                    <span>
                      Keep sharing your screen and kid’s screen all the time so
                      that whoever is observing you can understand what is
                      happening in the class.
                    </span>
                  </li>
                  <li>
                    <span>Every session should be between 55-60 minutes.</span>
                  </li>
                  <li>
                    <span>
                      Start the session with an introduction- your introduction,
                      ask for the kid's introduction and give an introduction of
                      technology to the kid. The introduction to technology
                      should be rooted in kid’s present context of technology.
                    </span>
                  </li>
                  <li>
                    <span>
                      After the introduction, do the suggested activity with the
                      kid.
                    </span>
                  </li>
                  <li>
                    <span>
                      Start with the teaching part and create a project with the
                      kid.
                    </span>
                  </li>
                  <li>
                    <span>
                      Proceed to parent interaction. Link to the interaction
                      best practices and script here : <br />
                      <a href='https://drive.google.com/file/d/1Yu0PHfFO0eW1qaWqjKerc2UIUKKzfutW/view?usp=sharing'>
                        Tips and Best practices for parent Interaction
                      </a>{' '}
                      <br />
                      <a href='https://drive.google.com/file/d/1wGEzkkFLf8T6-5bA29PY2uu7AdqEhrRg/view?usp=sharing'>
                        Script for Parent Teacher Interaction
                      </a>
                    </span>
                  </li>
                </ol>
                <h5>After</h5>
                <ol>
                  <li>
                    <span>After the class is over, “Fill the feedback”.</span>
                  </li>
                  <li>
                    <span>
                      Do not give generic feedback like “good”, “amazing”. Try
                      to be specific about what the child did well and the scope
                      for improvement.
                    </span>
                  </li>
                </ol>
              </div>
            ) : null}
            {currIndex === 2 ? (
              <div>
                <h1>Paid Classroom Protocol</h1>
                <h5>Beginning</h5>
                <ol>
                  <li>
                    <span>
                      Join 5 minute prior to the class starts to set up your
                      internet, mic, webcam etc.
                    </span>
                  </li>
                  <li>
                    <span>
                      Sit in a brightly lit place and make sure you look
                      professional.
                    </span>
                  </li>
                  <li>
                    <span>
                      There should be no potentially disturbing things in your
                      background like a pet or people talking.
                    </span>
                  </li>
                  <li>
                    <span>
                      Please put your phones on silent before you start the
                      class.
                    </span>
                  </li>
                  <li>
                    <span>
                      If the student doesn’t join, call the concerned person in
                      the next 5 min. If the student does not join within the
                      first 20 minutes, you can leave.
                    </span>
                  </li>
                </ol>
                <h5>During</h5>
                <ol>
                  <li>
                    <span>
                      Keep your camera on during the whole duration of the class
                      so that it is easy for the kid to connect to you.
                    </span>
                  </li>
                  <li>
                    <span>
                      Keep sharing your screen and kid’s screen all the time so
                      that whoever is observing you can understand what is
                      happening in the class.
                    </span>
                  </li>
                  <li>
                    <span>Every session should be between 55-60 minutes.</span>
                  </li>
                  <li>
                    <span>
                      Start the session with a quick recap of the previous
                      session.
                    </span>
                  </li>
                  <li>
                    <span>
                      Start with the teaching part and create a project with the
                      kid.
                    </span>
                  </li>
                </ol>
                <h5>After</h5>
                <ol>
                  <li>
                    <span>After the class is over, “Fill the feedback”.</span>
                  </li>
                  <li>
                    <span>
                      Do not give generic feedback like “good”, “amazing”. Try
                      to be specific about what the child did well and the scope
                      for improvement.
                    </span>
                  </li>
                </ol>
              </div>
            ) : null}
         
        </div>
      </div>
    </Base>
  )
}

export default Handbook
