import React, { useState } from "react";

const ProfileInput = ({
  label,
  type,
  placeholder,
  value,
  attr,
  onChange,
  countryList,
  cityList,
  stateList,
  state,
}) => {
  const inputType = () => {
    switch (type) {
      case "textarea":
        return (
          <textarea
            rows="10"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          ></textarea>
        );
      case "textarea2":
        return (
          <textarea
            rows="1"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          ></textarea>
        );
      case "readonly":
        return (
          <input type={type} placeholder={placeholder} value={value} readOnly />
        );
      case "selectCountry":
        return (
          <select
            class="custom-select"
            id="inputGroupSelect02"
            onChange={onChange}
          >
            <option selected>Select Country</option>
            {countryList?.map((country) => (
              <option
                key={country.name}
                value={`${country.name} ${country.isoCode}`}
              >
                {country.name}
              </option>
            ))}
          </select>
        );
      case "selectState":
        return (
          <select
            class="custom-select"
            id="inputGroupSelect03"
            onChange={onChange}
          >
            <option selected>select State </option>
            {stateList?.map((state) => (
              <option key={state.name} value={`${state.name} ${state.isoCode}`}>
                {state.name}
              </option>
            ))}
          </select>
        );
      case "selectCity":
        return (
          <select
            class="custom-select"
            id="inputGroupSelect04"
            onChange={onChange}
          >
            <option selected>Select City</option>
            {cityList?.map((state) => (
              <option key={state.name} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>
        );
      default:
        return (
          <input
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        );
    }
  };

  return (
    <div className=" profile_edit_input">
      <label>
        <b> {label} </b>
      </label>
      {inputType()}
    </div>
  );
};

export default ProfileInput;
