import React, { useState } from 'react'
import 'date-fns'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { todayDate } from '../Helper'
import './commonComponents.css'

const MonthPicker = ({ className, date, setDate }) => {
  const [selectedDate, setSelectedDate] = useState(todayDate())

  const handleDateChange = async (date) => {
    await setSelectedDate(date)
    await setDate(date)
  }
  return (
    <div className={`d-flex align-items-center ${className}`}>
      <span className='month_title mr-4'>Select Month</span>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container>
          <DatePicker
            variant='inline'
            views={['year', 'month']}
            openTo="month"
            value={date === '' ? new Date() : date}
            // formatDate={todayDate()}
            onChange={handleDateChange}
            // format='DD-MM-YYYY'
            autoOk={true}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default MonthPicker
