import React from "react";
import "./commonComponents.css";
import {AiFillCaretDown} from "react-icons/ai";

const TkDropDown = ({label, value, items, _onClick, style}) => {
  return (
    <div className="tk_dropdown ">
      <label>{label}</label>
      <div class="dropdown ">
        <button
          className="btn dropdown-toggle leave_type"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style={style}
        >
          {value}
          <AiFillCaretDown />
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          {items.map((item, index) => {
            return (
              <span class="dropdown-item" onClick={() => _onClick(item)}>
                {item}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TkDropDown;
