import React from "react";
import Rating from "@material-ui/lab/Rating";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const StarRating = ({name, value, setValue, field}) => {
  console.log(value);
  return (
    <Box component="fieldset" mb={3} borderColor="transparent">
      <div className="star_rating_container">
        <Typography component="legend" className="mr-3">
          {name}
        </Typography>
        <Rating
          name={name}
          value={value}
          onChange={(event, newValue) => {
            setValue(field, newValue);
          }}
        />
      </div>
    </Box>
  );
};

export default StarRating;
