import React, { useState } from "react";
import TkButton from "../../../Common/Components/TkButton";
import "./components.css";
import { updateClassStatus } from "../DashboardAPICalls";
import StatusModal from "../../../Common/Components/StatusModal";

const ClassStatusModal = ({
  showModal,
  handleModal,
  onModalBtnClick,
  classID,
  render,
  setRender,
  token,
}) => {
  const [close, setColse] = useState(showModal);
  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: "",
    statusText: "",
  });
  const updateStatus = async (status) => {
    await updateClassStatus(token, status, classID).then((data) => {
      if (data.error) {
        setStatusModal({
          ...statusModal,
          showStatusModal: true,
          status: "error",
          statusText: data.error,
        });
      } else {
        handleModal();
        setRender((prev) => prev + 1);
      }
    });
  };
  return (
    <>
      <div className={`modal ${showModal && "show_modal"}`}>
        <div className="modal_content">
          <span className="close" onClick={() => handleModal()}>
            &times;
          </span>
          <p>Select one option</p>
          <div className="status_modal_btn">
            <TkButton
              name={"60min successfully completed"}
              style={{ backgroundColor: "#00B23B" }}
              _onBtnClick={() => updateStatus("completed")}
            />

            <TkButton
              name={"Half Completed"}
              style={{ backgroundColor: "#e6ca17" }}
              _onBtnClick={() => updateStatus("halfcompleted")}
            />
            <TkButton
              name={"Cancelled"}
              style={{ backgroundColor: "#ff1e00" }}
              _onBtnClick={() => updateStatus("cancelled")}
            />
          </div>
          <StatusModal
            showModal={statusModal.showStatusModal}
            text={statusModal.statusText}
            status={statusModal.status}
            closeModal={() =>
              setStatusModal({ ...statusModal, showStatusModal: false })
            }
          />
        </div>
      </div>
    </>
  );
};

export default ClassStatusModal;
