import { base_route } from "../../backend";

let gradient;
let yearData;
// background: linear-gradient(180deg, rgba(107,0,128,1) 8%, rgba(230,230,230,1) 86%);
const getData = (canvas) => {
  const ctx = canvas.getContext("2d");
  gradient = ctx.createLinearGradient(0, 0, 0, 500);
  gradient.addColorStop(0, "#510061");
  gradient.addColorStop(1, "#fff");
};

const canvas = document.createElement("canvas");
const chartData = getData(canvas);
const getDataYear = () => {
  const t = JSON.parse(localStorage.getItem("tk_jwt"));
  if (t) {
    const token = JSON.parse(localStorage.getItem("tk_jwt")).token;
    fetch(`${base_route}/dashboard/teacher/myearnings`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "bearer-token": `${token}`,
      },
    })
      .then((result) => result.json())
      .then((data) => {
        changeData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
//console.log("chartConfig", chartConfig);
export const chartConfig = {
  type: "line",
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Earning",
      backgroundColor: gradient,
      borderColor: "#6b0080",
      data: getDataYear(),
    },
  ],
  options: {
    responsive: true,
    maintainAspectRatio: true,
    animation: {
      easing: "easeInOutQuad",
      duration: 520,
    },
    legend: {
      display: false,
    },

    plugins: {
      crosshair: {
        sync: {
          enabled: false,
        },
      },
    },
  },
};
const changeData = (earnings) => {
  chartConfig.datasets[0].data = earnings;

  chartConfig.update();
};
