import React, { useEffect, useState, useRef } from "react";
import "./commonComponents.css";
import paidCalendar from "../../Assets/Image/paidCalendar.png";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import TkButton from "./TkButton";
import { useHistory } from "react-router-dom";

const ClassDetail = ({ name, subName, grade, classTime }) => {
  const showClassdate = (date) => {
    if (date != undefined) {
      return (
        date.substring(8, 10) +
        " " +
        date.substring(4, 8) +
        ", " +
        date.substring(16, 25)
      );
    }
    return "";
  };
  return (
    <div className="trial_info">
      <div className="trial_info_box d-flex justify-content-between align-items-center mt-3">
        <span className="trial_user_name">{name}</span>
        <span className="trial_subject_name">
          {subName} Class-
          {grade}
        </span>
        <div className="paid_calendar d-flex align-items-center">
          <img src={paidCalendar} alt="" className="mr-2 calendar_img" />
          <span>{showClassdate(classTime)}</span>
        </div>
      </div>
    </div>
  );
};

export default ClassDetail;
