import React, { useEffect, useContext, useState, useRef } from "react";
import Base from "../../Common/Components/Base";
import MonthPicker from "../../Common/Components/MonthPicker";
import "./Earning.css";
import { UserContext } from "../../App";
import { Line } from "react-chartjs-2";
import { chartConfig, options } from "./Earningdata";
import { getDataBydate } from "./EarningAPIcalls";

const Earning = () => {
  const chartContainer = useRef(null);
  const { token, userData } = useContext(UserContext);
  const [chartInstance, setChartInstance] = useState(null);
  const [date, setDate] = useState(new Date());
  const [data, setData] = useState({
    trialTaken: 0,
    trialConverted: 0,
    trialCancelled: 0,
    PaidClassINR: 0,
    PaidClassEINR: 0,
    panalty: 0,
  });

  useEffect(() => {
    getDataBydate(token, date).then((res) => {
      console.log("result", res);
      setData(res);
    });
  }, [date]);
  return (
    <Base>
      <div className="w_85 mx-auto">
        <MonthPicker date={date} setDate={setDate} />
        <div className="month_total">
          <span className="month_text">This Month Total</span>
          <span className="month_price">
            {"\u20B9 "}
            {data.trialConverted * 200 +
              data.trialTaken * 50 +
              data.trialCancelled * 0 -
              data.panalty * 200 +
              data.PaidClassINR * 150 +
              data.PaidClassEINR * 250}
          </span>
        </div>
        <div className="earn_type">
          <div className="earn_type_item">
            <span className="earn_text">Trial Taken</span>
            <span className="earn_price">
              {"\u20A8 "}
              {data.trialTaken * 50}
            </span>
          </div>
          <div className="earn_type_item">
            <span className="earn_text">Trial Converted</span>
            <span className="earn_price">
              {"\u20A8 "}
              {data.trialConverted * 200}
            </span>
          </div>
          <div className="earn_type_item">
            <span className="earn_text">Int Paid Class</span>
            <span className="earn_price">
              {"\u20A8 "}
              {data.PaidClassEINR * 250}
            </span>
          </div>
          <div className="earn_type_item">
            <span className="earn_text">Paid Classes</span>
            <span className="earn_price">
              {"\u20A8 "}
              {data.PaidClassINR * 150}
            </span>
          </div>
          <div className="earn_type_item">
            <span className="earn_text">Penalty</span>
            <span className="earn_price">
              {"\u20A8 "}- {data.panalty * 200}
            </span>
          </div>
        </div>
        <div className="earn_chart">
          <Line data={chartConfig} />
        </div>
      </div>
    </Base>
  );
};

export default Earning;
