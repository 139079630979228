import React, { useEffect, useState } from "react";
import PrivateRoute from "./Common/Components/PrivateRoute";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CourseDetailList from "./pages/Courses/Components/CourseDetailList";
import CourseDetail from "./pages/Courses/Components/CourseDetail";
import Courses from "./pages/Courses/Courses";
import Dashboard from "./pages/Dashboard/Dashboard";
import Feedback from "./pages/Dashboard/Feedback";
import Paidfeedback from "./pages/Dashboard/Paidfeedback";
import Earning from "./pages/Earning/Earning";
import Handbook from "./pages/HandBook/Handbook";
import InviteWin from "./pages/InviteWin/InviteWin";
import Leave from "./pages/Leave/Leave";
import Overlay from "./Common/Components/Overlay";
import LeaveApplication from "./pages/Leave/LeaveApplication";
import { getProfile, isAuthenticated } from "./pages/Login/LoginAPICalls";
import Login from "./pages/Login/Login";
import Schedule from "./pages/Schedule/Schedule";
import ChangePassword from "./pages/Setting/Routes/ChangePassword";
import ContactUs from "./pages/Setting/Routes/ContactUs";
import EditProfile from "./pages/Setting/Routes/Profile/EditProfile";
import Profile from "./pages/Setting/Routes/Profile/Profile";
import TechSupport from "./pages/Setting/Routes/TechSupport";
import TicketCreate from "./pages/Setting/Routes/Ticket/TicketCreate";
import TicketSupport from "./pages/Setting/Routes/Ticket/TicketSupport";
import Setting from "./pages/Setting/Setting";
import TrialTaken from "./pages/Dashboard/Components/TrialTaken";
import TrialCancelled from "./pages/Dashboard/Components/TrialCancelled";
import Panalty from "./pages/Dashboard/Components/Panalty";
import TrialConverted from "./pages/Dashboard/Components/TrialConverted";
import PaidClasses from "./pages/Dashboard/Components/PaidClasses";
import LoginWithOTP from "./pages/Login/LoginWithOTP";
import ResetPassword from "./pages/Login/ResetPassword";
export const UserContext = React.createContext();
const App = () => {
  const [profileInfo, setProfileInfo] = useState({});
  const [loading, setLoading] = useState(false);
  let token, email;
  token = isAuthenticated().token;
  email = isAuthenticated().email;
  console.log("email", email);
  useEffect(() => {
    if (token !== undefined) {
      setLoading(true);
      getUserProfile().then((data) => {
        setProfileInfo(data);
      });
    }
  }, []);

  const getUserProfile = () => {
    return getProfile(token)
      .then((response) => {
        //console.log("response", response);
        return response;
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <UserContext.Provider
        value={{
          userData: profileInfo,
          loading: loading,
          setLoading,
          setProfileInfo,
          token: token,
        }}
      >
        <Router>
          {loading && <Overlay />}
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/login-with-otp" exact component={LoginWithOTP} />
            <Route path="/reset-password" exact component={ResetPassword} />
            <PrivateRoute path="/" exact component={Dashboard} />
            <PrivateRoute path="/dashboard" exact component={Dashboard} />
            <PrivateRoute
              path="/dashboard/trial-taken"
              exact
              component={TrialTaken}
            />
            <PrivateRoute
              path="/dashboard/trial-converted"
              exact
              component={TrialConverted}
            />
            <PrivateRoute
              path="/dashboard/trial-cancelled"
              exact
              component={TrialCancelled}
            />
            <PrivateRoute path="/dashboard/panalty" exact component={Panalty} />
            <PrivateRoute
              path="/dashboard/paid-classes"
              exact
              component={PaidClasses}
            />
            <PrivateRoute
              path="/dashboard/feedback/:id"
              exact
              component={Feedback}
            />
            <PrivateRoute
              path="/dashboard/paidfeedback/:id"
              exact
              component={Paidfeedback}
            />
            <PrivateRoute path="/schedule" exact component={Schedule} />
            <PrivateRoute path="/courses" exact component={Courses} />
            <PrivateRoute
              path="/courses/:name"
              exact
              component={CourseDetailList}
            />
            <PrivateRoute
              path="/courses/:name/:class"
              exact
              component={CourseDetail}
            />
            <PrivateRoute path="/earning" exact component={Earning} />
            <PrivateRoute path="/referral" exact component={InviteWin} />
            <PrivateRoute path="/leave" exact component={Leave} />
            <PrivateRoute
              path="/leave/form"
              exact
              component={LeaveApplication}
            />
            <PrivateRoute path="/handbook" exact component={Handbook} />
            <PrivateRoute path="/setting" exact component={Setting} />
            <PrivateRoute
              path="/setting/support/ticket"
              exact
              component={TicketSupport}
            />
            <PrivateRoute
              path="/setting/create/ticket"
              exact
              component={TicketCreate}
            />
            <PrivateRoute
              path="/setting/support/tech"
              exact
              component={TechSupport}
            />
            <PrivateRoute
              path="/setting/contactus"
              exact
              component={ContactUs}
            />
            <PrivateRoute
              path="/setting/change/password"
              exact
              component={ChangePassword}
            />
            <PrivateRoute path="/setting/profile" exact component={Profile} />
            <PrivateRoute
              path="/setting/profile/edit"
              exact
              component={EditProfile}
            />
          </Switch>
        </Router>
      </UserContext.Provider>
    </>
  );
};

export default App;
