import React, {useState} from "react";
import Base from "../../../Common/Components/Base";
import {AiOutlineArrowLeft} from "react-icons/ai";
import {FiArrowLeftCircle, FiArrowRightCircle} from "react-icons/fi";
import {useHistory, useLocation} from "react-router-dom";
import {capitalize} from "./Helper";
import "./components.css";
import Content from "./Content";

const CourseDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const [currClassNo, setcurrClassNo] = useState(null);

  let courseName = location.state.courseName;
  let classNo = capitalize(location.state.classNo);
  let number = parseInt(classNo.slice(classNo.length - 1));

  const urlToNextClass = (classNumber) => {
    setcurrClassNo(classNumber);
    const nextClassString = `class ${classNumber}`;
    history.push({
      pathname: `/courses/${courseName}/class ${nextClassString}`,
      state: {courseName: courseName, classNo: nextClassString},
    });
  };
  return (
    <Base>
      <div className="w_85 mx-auto course_detail">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <AiOutlineArrowLeft
            size={30}
            color="#6b0080"
            onClick={() => {
              history.push({
                pathname: `/courses/${courseName}`,
                state: {courseName},
              });
            }}
          />
          <div className="d-flex align-items-center flex-column">
            <h1>{classNo}</h1>
            <h4>Small Description</h4>
          </div>
          <div>
            <FiArrowLeftCircle
              size={30}
              color="#6b0080"
              className="mr-2"
              onClick={() =>
                currClassNo === 1 ? null : urlToNextClass(number - 1)
              }
            />

            <FiArrowRightCircle
              size={30}
              color="#6b0080"
              onClick={() =>
                currClassNo === 5 ? null : urlToNextClass(number + 1)
              }
            />
          </div>
        </div>
        <div>
          <Content courseName={courseName} classNo={classNo} />
        </div>
      </div>
    </Base>
  );
};

export default CourseDetail;
