import React, { useState, useContext } from "react";
import BackNavigation from "../../Common/Components/BackNavigation";
import { useParams, useHistory } from "react-router-dom";
import Base from "../../Common/Components/Base";
import TkDropDown from "../../Common/Components/DropDown";
import TkButton from "../../Common/Components/TkButton";
import StarRating from "./Components/StarRating";
import "./Dashboard.css";
import { UserContext } from "../../App";
import StatusModal from "../../Common/Components/StatusModal";
import { addFeedback } from "./DashboardAPICalls";
const Feedback = () => {
  const [chanceOfBuy, setchanceOfBuy] = useState("HOT");
  const [chanceOfBuy2, setchanceOfBuy2] = useState("YES");
  const [feedback, setFeedback] = useState("");
  const { id } = useParams();
  //console.log(id);
  const history = useHistory();
  const [reason, setReason] = useState("");
  const [notPosted, setNotPosted] = useState(true);
  const { token } = useContext(UserContext);
  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: "",
    statusText: "",
  });

  const [rating, setRating] = useState({
    logic: null,
    concentration: null,
    creativity: null,
    memory: null,
  });

  const handleSubmit = async () => {
    //console.log(feedback, chanceOfBuy, chanceOfBuy2, notPosted, rating, reason);
    await addFeedback(
      token,
      feedback,
      chanceOfBuy,
      chanceOfBuy2,
      notPosted,
      rating,
      reason,
      id
    ).then((data) => {
      if (data.error) {
        setStatusModal({
          ...statusModal,
          showStatusModal: true,
          status: "error",
          statusText: data.error,
        });
      } else {
        setStatusModal({
          ...statusModal,
          showStatusModal: true,
          status: "success",
          statusText: data.message,
        });
        history.push("/dashboard");
        window.location.reload();
      }
    });
  };
  const handleOnChange = (field, value) => {
    setRating({ ...rating, [field]: value });
  };

  return (
    <Base>
      <div className="w_85 mx-auto">
        <BackNavigation title={"Feedback"} color={"#000"} />

        <form className="w_85 mx-auto">
          <div className="feedback_item">
            <TkDropDown
              label="Chances of Buying"
              _onClick={setchanceOfBuy}
              style={{ width: 250 }}
              value={chanceOfBuy}
              items={["HOT", "WARM", "COLD", "DEAD"]}
            />
          </div>
          <div className="feedback_item">
            <label>Chances of Buying</label>
            <ul className="d-flex feedback_radio">
              <li className="mr-3 radio_yes">
                <input
                  type="radio"
                  value="YES"
                  name="radio"
                  id="radio1"
                  className="mr-2"
                  onChange={(e) => setchanceOfBuy2(e.target.value)}
                />
                <label for="radio1" className="radio_label">
                  Yes
                </label>
              </li>
              <li className="radio_no">
                <input
                  type="radio"
                  value="NO"
                  name="radio"
                  id="radio2"
                  className="mr-2"
                  onChange={(e) => setchanceOfBuy2(e.target.value)}
                />
                <label for="radio2" className="radio_label">
                  No
                </label>
              </li>
            </ul>
          </div>
          <div className="feedback_item">
            <label>Student Competence</label>
            <StarRating
              value={rating.logic}
              setValue={handleOnChange}
              field="logic"
              name="Logic"
            />
            <StarRating
              value={rating.concentration}
              setValue={handleOnChange}
              field="concentration"
              name="Concentration"
            />
            <StarRating
              value={rating.creativity}
              setValue={handleOnChange}
              field="creativity"
              name="Creativity"
            />
            <StarRating
              value={rating.memory}
              setValue={handleOnChange}
              field="memory"
              name="Memory"
            />
          </div>
          <div className="feedback_item">
            <label>
              Has your student posted his/her Project/Certificate and tagged
              @techokids on social media
            </label>
            <ul className="d-flex feedback_radio">
              <li className="mr-3 radio_yes">
                <input
                  type="radio"
                  value={notPosted}
                  name="radio1"
                  id="radio11"
                  className="mr-2 "
                  onChange={(e) => setNotPosted(true)}
                />
                <label for="radio11" className="radio_label">
                  Yes
                </label>
              </li>

              <li className="radio_no">
                <input
                  type="radio"
                  value={notPosted}
                  name="radio1"
                  id="radio22"
                  className="mr-2 "
                  onChange={(e) => setNotPosted(false)}
                />
                <label for="radio22" className="radio_label">
                  No
                </label>
              </li>
            </ul>
            {notPosted == false ? (
              <div className="feedback_item">
                <textarea
                  placeholder="reason"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                ></textarea>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="feedback_item">
            <label>Feedback</label>
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            ></textarea>
          </div>

          <TkButton
            name={"Submit"}
            style={{ backgroundColor: "#00B23B" }}
            type="submit"
            _onBtnClick={() => handleSubmit()}
          />
        </form>
        <StatusModal
          showModal={statusModal.showStatusModal}
          text={statusModal.statusText}
          status={statusModal.status}
          closeModal={() =>
            setStatusModal({ ...statusModal, showStatusModal: false })
          }
        />
      </div>
    </Base>
  );
};

export default Feedback;
