import React, { useState, useContext, useEffect } from "react";
import { base_route } from "../../backend";
import Base from "../../Common/Components/Base";
import "./Dashboard.css";
import InfoBox from "../../Common/Components/InfoBox";
import MonthPicker from "../../Common/Components/MonthPicker";
import ClassStatusModal from "./Components/StatusModal";
import { useHistory } from "react-router-dom";
import NotificationBell from "../../Assets/Image/NotificationBell.png";
import { getGreeting } from "./Components/Helper";
import { trialClassData } from "./data";
import { UserContext } from "../../App";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import {
  getProfile,
  getSchedule,
  getDashboardData,
  getNoti,
  addSeen,
} from "./DashboardAPICalls";
import PasswordModal from "./Components/PasswordModal";
const Dashboard = () => {
  const [radioBtnActive, setradioBtnActive] = useState("trial");
  const [showModal, setShowModal] = useState(false);
  const [modalBtnClickedItem, setmodalBtnClickedItem] = useState("On Going");
  const [currClicked, setCurrCliked] = useState(null);
  const [showNoti, setshowNoti] = useState(false);
  const [date, setDate] = useState(new Date());
  const [classID, setClassID] = useState("");
  const [noti, setNoti] = useState([]);
  const [render, setRender] = useState(0);
  const [upcomingClasses, setUpcomingClasses] = useState([
    { studentName: "", remark: "" },
  ]);
  const [dashboardData, setDashboardData] = useState({
    trialTaken: 0,
    trialConverted: 0,
    trialCancelled: 0,
    PaidClassINR: 0,
    PaidClassEINR: 0,
    panalty: 0,
  });
  const { userData, token, setProfileInfo, setLoading } = useContext(
    UserContext
  );
  // console.log("userData", userData);
  const history = useHistory();
  const [successModal, setSuccessModal] = useState({
    successModal: true,
    successText: "",
  });
  const handleModal = (ID) => {
    setClassID(ID);
    setShowModal(!showModal);
  };

  const handleDownload = (link) => {
    if (link != "") {
      window.open(`${base_route}/${link}`, "_blank");
    }
  };

  const onModalBtnClick = (btnItem) => {
    //setmodalBtnClickedItem(btnItem);
    setShowModal(!showModal);
  };
  const displayName = (name) => {
    if (name != undefined) {
      const words = name.split(" ");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      console.log("W", words);
      var k = words.join(" ");
      return k;
    }
    return "";
  };
  useEffect(async () => {
    await getSchedule(token).then((data) => {
      var result = data.sort((a, b) => {
        return new Date(a.slot) - new Date(b.slot);
      });
      console.log("My coming classes", data);
      setUpcomingClasses(result);
    });
    await getNoti().then((data) => {
      console.log("noti", data);
      var D = data.reverse();
      setNoti(D);
    });
  }, [render]);

  useEffect(async () => {
    await getDashboardData(token, date).then((data) => {
      setDashboardData(data);
    });
  }, [date]);

  //console.log("emakl", userData.email);
  const getUnseen = () => {
    if (noti) {
      var count = 0;
      var email = userData.email;
      for (var i = 0; i < noti.length; i++) {
        var n = noti[i].seenBy;
        if (!n.includes(email)) {
          count += 1;
        }
      }
      return count;
    }
    return 0;
  };
  const getRegDate = (ID) => {
    if (ID != undefined) {
      var s = ID;
      var timehex = s.substring(0, 8);
      var secondsSinceEpoch = parseInt(timehex, 16);
      var t = new Date(secondsSinceEpoch * 1000).toString();
      return (
        new Date(secondsSinceEpoch * 1000).toDateString().substring(4, 10) +
        " " +
        "|" +
        " " +
        t.slice(15, 21)
      );
    } else return "";
  };

  const onUpdate = async () => {
    setshowNoti(!showNoti);
    if (showNoti == false) {
      var update = [];
      var n = noti.length;
      var i = 0;
      while (i < 3) {
        if (noti[i] != undefined) {
          // console.log(noti[i]);
          update.push(noti[i]._id);
          i += 1;
        } else {
          break;
        }
      }
      // console.log("Update", update);
      await addSeen(update, userData.email).then((res) => {
        //console.log("res", res);
        setRender((prev) => prev + 1);
      });
    }
  };

  return (
    <Base>
      <div className="w_85 mx-auto">
        <div className="dashboard_top d-flex justify-content-between">
          <h1>
            {getGreeting()} {displayName(userData.name)}
          </h1>
          <div class="bellicon" onClick={() => onUpdate()}>
            <img src={NotificationBell} alt="Notification Bell" />
            <div class="noti_no">{getUnseen()}</div>
          </div>

          <div className={`notification_view ${!showNoti && "hide"}`}>
            <div>
              {noti.map((item) => (
                <div>
                  <div className="d-flex justify-content-between">
                    <h5 className="noti_title">
                      <b>{item.title}</b>
                    </h5>
                    <span className="noti_date text-secondary">
                      <b
                        onClick={() => handleDownload(item.media)}
                        className="m-3"
                      >
                        {item.media != "" ? (
                          <>
                            <AttachFileIcon className="attach_file" />
                            file
                          </>
                        ) : (
                          <></>
                        )}
                      </b>
                      <b>{getRegDate(item._id)}</b>
                    </span>
                  </div>
                  <p className="noti_desc">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <MonthPicker date={date} setDate={setDate} />
        <div className="trial_view">
          <div
            className="trial_item "
            onClick={() => history.push("/dashboard/trial-taken")}
          >
            <span className="trial_num">{dashboardData.trialTaken}</span>
            <span className="trial_title">Trial Taken</span>
          </div>

          <div
            className="trial_item"
            onClick={() => history.push("/dashboard/trial-converted")}
          >
            <span className="trial_num">{dashboardData.trialConverted}</span>
            <span className="trial_title">Trial Converted</span>
          </div>

          <div
            className="trial_item "
            onClick={() => history.push("/dashboard/trial-cancelled")}
          >
            <span className="trial_num">{dashboardData.trialCancelled}</span>
            <span className="trial_title">Trial Cancelled</span>
          </div>

          <div
            className="trial_item "
            onClick={() => history.push("/dashboard/paid-classes")}
          >
            <span className="trial_num">
              {dashboardData.PaidClassINR + dashboardData.PaidClassEINR}
            </span>
            <span className="trial_title">Paid Classes</span>
          </div>

          <div
            className="trial_item "
            onClick={() => history.push("/dashboard/panalty")}
          >
            <span className="trial_num">{dashboardData.panalty}</span>
            <span className="trial_title">Penalty</span>
          </div>
        </div>
        <span className="dashboard_title d-block">Upcoming Classes</span>
        <div className="btn-group btn-group-toggle mt-3" data-toggle="buttons">
          <label
            className={`btn upcoming_radio ${
              radioBtnActive === "trial" && `upcoming_radio_active`
            }`}
            onClick={() => setradioBtnActive("trial")}
          >
            <input type="radio" name="options" id="option1" defaultChecked />{" "}
            Trial
          </label>
          <label
            className={`btn upcoming_radio ${
              radioBtnActive === "paid" && `upcoming_radio_active`
            }`}
            onClick={() => setradioBtnActive("paid")}
          >
            <input type="radio" name="options" id="option2" /> Paid
          </label>
        </div>

        <div className="mt-5">
          {radioBtnActive == "trial" ? (
            <>
              {upcomingClasses.map((item, index) => {
                if (item.classtype != "paid") {
                  if (
                    item.halfCompleted == false &&
                    item.cancelled == false &&
                    item.trailCompleted == true &&
                    item.feedback.length < 1
                  ) {
                    return (
                      <InfoBox
                        classData={item}
                        name={item.studentName}
                        subName={item.remark}
                        grade={item.grade}
                        classTime={item.slot}
                        _icon={radioBtnActive}
                        meetingLink={userData.meetingLink}
                        _onBtnClick={() => handleModal(item._id)}
                      />
                    );
                  }
                  if (
                    item.halfCompleted == false &&
                    item.cancelled == false &&
                    item.trailCompleted == false &&
                    item.feedback.length < 1
                  ) {
                    return (
                      <InfoBox
                        classData={item}
                        name={item.studentName}
                        subName={item.remark}
                        grade={item.grade}
                        classTime={item.slot}
                        _icon={radioBtnActive}
                        meetingLink={userData.meetingLink}
                        _onBtnClick={() => handleModal(item._id)}
                      />
                    );
                  }
                }
              })}
            </>
          ) : (
            <>
              {upcomingClasses.map((item, index) => {
                if (item.classtype == "paid") {
                  if (
                    item.halfCompleted == false &&
                    item.cancelled == false &&
                    (item.paidClassINR == true || item.paidClassEINR == true) &&
                    item.feedback.length < 1
                  ) {
                    return (
                      <InfoBox
                        classData={item}
                        name={item.studentName}
                        subName={item.remark}
                        grade={item.grade}
                        classTime={item.slot}
                        _icon={radioBtnActive}
                        meetingLink={userData.meetingLink}
                        _onBtnClick={() => handleModal(item._id)}
                      />
                    );
                  }
                  if (
                    item.halfCompleted == false &&
                    item.cancelled == false &&
                    item.paidClassINR == false &&
                    item.paidClassEINR == false &&
                    item.feedback.length < 1
                  ) {
                    return (
                      <InfoBox
                        classData={item}
                        name={item.studentName}
                        subName={item.remark}
                        grade={item.grade}
                        classTime={item.slot}
                        _icon={radioBtnActive}
                        meetingLink={userData.meetingLink}
                        _onBtnClick={() => handleModal(item._id)}
                      />
                    );
                  }
                }
              })}
            </>
          )}
        </div>
        <ClassStatusModal
          showModal={showModal}
          handleModal={handleModal}
          classID={classID}
          token={token}
          render={render}
          setRender={setRender}
          onModalBtnClick={onModalBtnClick}
        />
      </div>
      {userData.password === "" && (
        <PasswordModal
          showModal={successModal.successModal}
          closeModal={() =>
            setSuccessModal({ ...successModal, successModal: false })
          }
          email={userData.email}
          token={token}
        />
      )}
    </Base>
  );
};

export default Dashboard;
