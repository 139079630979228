import React from "react";
import { ReactComponent as Dashbord } from "../../Assets/Image/dashboard.svg";
import { ReactComponent as Calender } from "../../Assets/Image/calender.svg";
import { ReactComponent as Earning } from "../../Assets/Image/earning.svg";
import { ReactComponent as Invite } from "../../Assets/Image/invite.svg";
import { ReactComponent as Courses } from "../../Assets/Image/courses.svg";
import { ReactComponent as Leave } from "../../Assets/Image/leave.svg";
import { ReactComponent as Handbook } from "../../Assets/Image/handbook.svg";
import { ReactComponent as Setting } from "../../Assets/Image/setting.svg";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <Dashbord />,
    cName: "nav-text",
  },
  {
    title: "Schedule",
    path: "/schedule",
    icon: <Calender />,
    cName: "nav-text no_svg_clr",
  },
  {
    title: "Courses",
    path: "/courses",
    icon: <Courses />,
    cName: "nav-text",
  },
  {
    title: "Earning",
    path: "/earning",
    icon: <Earning />,
    cName: "nav-text",
  },
  {
    title: "Invite & Win",
    path: "/referral",
    icon: <Invite />,
    cName: "nav-text",
  },
  {
    title: "Leave",
    path: "/leave",
    icon: <Leave />,
    cName: "nav-text no_svg_clr ",
  },
  {
    title: "Hanbook",
    path: "/handbook",
    icon: <Handbook />,
    cName: "nav-text no_svg_clr",
  },
  {
    title: "Setting",
    path: "/setting",
    icon: <Setting />,
    cName: "nav-text",
  },
];
