import React from "react";
import "./Components.css";
import { weekData } from "./helper";

const WeekCalendar = ({
  onDateSelect,
  selectedDate,
  slots,
  setSlots,
  localSlot,
  setLocalSlot,
}) => {
  // const weekStartDate = selectedDate.clone().startOf("week").isoWeekday(4);
  // const weekEndDate = selectedDate.clone().endOf("week").isoWeekday(4);
  // console.log("Week", weekStartDate.toString(), weekEndDate.toString());
  const cloneDay = selectedDate.clone();

  const day = cloneDay.clone().subtract(1, "day");

  const calendar = [];
  for (let i = 0; i < 7; i++) {
    calendar.push(day.add(1, "day").clone());
  }

  const isAfterThree = (afterDate) => {
    let _afterDate = cloneDay.clone().add(7, "day");

    if (afterDate.isAfter(_afterDate, "day")) return "disable_day";
  };

  const check = (date) => {
    if (slots.filter((e) => e.slot === date).length > 0) {
      let obj = slots.find((o) => o.slot === date);
      if (obj.studentName == "") {
        return "slot_available";
      } else if (
        obj.studentName != "" &&
        (obj.halfCompleted || obj.cancelled)
      ) {
        return "slot_cancel";
      } else if (obj.studentName != "" && obj.classtype == "paid") {
        return "slot_paid_booked";
      } else {
        return "slot_booked";
      }
    } else if (localSlot.filter((e) => e.slot === date).length > 0) {
      return "slot_locked";
    } else {
      return "";
    }
  };
  const bookedSlotInfo = (date) => {
    if (slots.filter((e) => e.slot === date).length > 0) {
      let obj = slots.find((o) => o.slot === date);
      // console.log("OBJ at schedule", obj);
      if (obj.halfCompleted || obj.cancelled) {
        return <h6>CANCEL</h6>;
      } else if (obj.studentName != "" && obj.classtype == "paid") {
        return <h6>PAID CLASS</h6>;
      } else if (obj.studentName != "") {
        return <h6>TRIAL BOOKED</h6>;
      } else {
        return <h6>AVAILABLE</h6>;
      }
    } else if (localSlot.filter((e) => e.slot === date).length > 0) {
      return <h6>AVAILABLE-B</h6>;
    } else {
      return "";
    }
  };
  const handleOnClick = (timing, index, event) => {
    // let date = { time: timing, event: calendar[event.target.cellIndex - 1] };
    var dayAfter31 = new Date().getTime() + 2678400000;
    var dayAfter2 = new Date().getTime() + 48 * 60 * 60 * 1000;
    var selected = new Date(calendar[index]._d).toDateString() + " " + timing;
    var curr = new Date(selected).getTime();
    if (curr > dayAfter31) {
      return; //to prevent booking after 31 day
    }
    if (curr < dayAfter2) {
      return; //to prevent booking before day after tomorrow
    }
    if (slots.filter((e) => e.slot === selected).length > 0) {
      return;
    } else if (localSlot.filter((e) => e.slot === selected).length > 0) {
      const newdata = localSlot.filter((item) => item.slot !== selected);
      setLocalSlot(newdata);
    } else {
      var newslot = { slot: selected };
      setLocalSlot((prev) => [...prev, newslot]);
    }

    // onDateSelect(date);
    let date = { time: timing, event: calendar[event.target.cellIndex - 1] };
    onDateSelect(date);
  };
  return (
    <div className="table_div">
      <table className="body table table-bordered week_table">
        <thead className="day_names ">
          <tr>
            <th className="head_timing">Timings</th>

            {calendar.slice(0, 7).map((day) => (
              <th className={`week ${isAfterThree(day)}`}>
                <div className="week_head">
                  <span>{day.format("ddd").toString()}</span>
                  <span>{day.format("D").toString()}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {weekData.timings.map((timings) => {
            return (
              <tr>
                <td className="timing">{timings}</td>
                {weekData.weekName.slice(1, 8).map((item, index) => (
                  <td
                    className={`day ${check(
                      new Date(calendar[index]._d).toDateString() +
                        " " +
                        timings
                    )}`}
                    onClick={(e) => {
                      handleOnClick(timings, index, e);
                      // onDateSelect({timings, e});
                    }}
                  >
                    {bookedSlotInfo(
                      new Date(calendar[index]._d).toDateString() +
                        " " +
                        timings
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default WeekCalendar;
