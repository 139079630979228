import React, { useState, useContext, useEffect } from "react";
import Base from "../../Common/Components/Base";
import "./Schedule.css";
import moment from "moment";
import { useHistory } from "react-router";
import Calendar from "./Components/Calendar";
import WeekCalendar from "./Components/WeekCalendar";
import CalendarHeader from "./Components/CalendarHeader";
import DayCalendar from "./Components/DayCalendar";
import { UserContext } from "../../App";
import { getSchedule, updateSchedule } from "./ScheduleAPICalls";
import StatusModal from "../../Common/Components/StatusModal";
import ColorDetails from "./Components/ColorDetails";
const Schedule = () => {
  const [value, setValue] = useState(moment());
  const [calendarType, setcalendarType] = useState("Month");
  const [ScheduledDate, setScheduledDate] = useState(new Date());
  const [ScheduledWeekDate, setScheduledWeekDate] = useState({});
  const [ScheduledDayTime, setScheduledDayTime] = useState({});
  const [slots, setSlots] = useState([{}]);
  const [render, setRender] = useState(0);
  const [localSlot, setLocalSlot] = useState([]);
  const [isclicked, setIsClicked] = useState(false);
  //console.log("slost at header", slots);
  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: "",
    statusText: "",
  });
  let history = useHistory();
  const { token } = useContext(UserContext);
  const setData = async (data) => {
    setValue(data);
    setScheduledDate(data);
    await setcalendarType("Week");
    setcalendarType("Week");
  };

  const setWeekDay = (data) => {
    setScheduledWeekDate(data);
  };
  const setDayTime = (date) => {
    setScheduledDayTime(date);
  };

  const calendarView = () => {
    if (calendarType === "Month") {
      return <Calendar value={value} onChange={setData} />;
    } else if (calendarType === "Week") {
      return (
        <WeekCalendar
          selectedDate={value}
          onDateSelect={setWeekDay}
          slots={slots}
          setSlots={setSlots}
          localSlot={localSlot}
          setLocalSlot={setLocalSlot}
        />
      );
    } else if (calendarType === "Day") {
      return (
        <DayCalendar
          onDateSelect={setDayTime}
          date={ScheduledDate}
          slots={slots}
          setSlots={setSlots}
          localSlot={localSlot}
          setLocalSlot={setLocalSlot}
        />
      );
    }
  };

  useEffect(() => {
    if (token) {
      getSchedule(token).then((res) => {
        //console.log("arry of object", res);
        setSlots(res);
      });
    }
  }, [token, render]);

  const handleSubmit = () => {
    var dict = {};
    setIsClicked(true);
    for (var i = 0; i < localSlot.length; i++) {
      var slotday = localSlot[i].slot.slice(0, 15);
      if (!dict[slotday]) {
        dict[slotday] = 1;
      } else {
        dict[slotday] += 1;
      }
    }
    var notallowed = false;
    for (var i in dict) {
      var daynum = new Date(i).getDay();
      var count = dict[i];
      if (daynum == 6 || daynum == 0) {
        if (count < 5) {
          notallowed = true;
          break;
        }
      } else {
        if (count < 4) {
          notallowed = true;
          break;
        }
      }
    }
    if (notallowed) {
      setStatusModal({
        ...statusModal,
        showStatusModal: true,
        status: "error",
        statusText: "Mon-Fri (Minimum 4 ) & Sun- Sat ( Minimum -5 )",
      });
      setIsClicked(false);
      return;
    }

    updateSchedule(localSlot, token).then((resp) => {
      if (resp.error) {
        setStatusModal({
          ...statusModal,
          showStatusModal: true,
          status: "error",
          statusText: resp.error,
        });
        return;
      } else {
        setStatusModal({
          ...statusModal,
          showStatusModal: true,
          status: "success",
          statusText: resp.message,
        });
        setRender((prev) => prev + 1);
        //window.location.reload();
      }
      setIsClicked(false);
    });
  };
  return (
    <Base>
      <div className="mx-auto">
        <CalendarHeader
          value={value}
          setValue={setValue}
          dropDownValue={calendarType}
          setScheduledDate={setScheduledDate}
          dropDownChange={setcalendarType}
        />
        <h6>{localSlot.length == 0 ? "" : "Save Your Changes"}</h6>
        {calendarView()}
      </div>
      <div>
        {calendarType != "Month" ? (
          <button
            className="save_button"
            onClick={() => handleSubmit()}
            disabled={isclicked}
          >
            {isclicked ? "Saving.." : "Save"}
          </button>
        ) : (
          <></>
        )}
        <StatusModal
          showModal={statusModal.showStatusModal}
          text={statusModal.statusText}
          status={statusModal.status}
          closeModal={() => {
            setStatusModal({ ...statusModal, showStatusModal: false });
          }}
        />
        <ColorDetails></ColorDetails>
      </div>
    </Base>
  );
};

export default Schedule;
