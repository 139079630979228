import { base_route } from "../../backend";

export const updateSchedule = (data, token) => {
  return fetch(`${base_route}/slot/updateSlots`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "bearer-token": `${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const getSchedule = (token) => {
  return fetch(`${base_route}/slot/myslot`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "bearer-token": `${token}`,
    },
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
