import React, { useState, useContext } from "react";
import BackNavigation from "../../Common/Components/BackNavigation";
import { useParams, useHistory } from "react-router-dom";
import Base from "../../Common/Components/Base";
import TkDropDown from "../../Common/Components/DropDown";
import TkButton from "../../Common/Components/TkButton";
import StarRating from "./Components/StarRating";
import "./Dashboard.css";
import { UserContext } from "../../App";
import StatusModal from "../../Common/Components/StatusModal";
import { addPaidFeedback } from "./DashboardAPICalls";
const Paidfeedback = () => {
  const [feedback, setFeedback] = useState("");
  const { id } = useParams();
  const history = useHistory();
  const { token } = useContext(UserContext);
  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: "",
    statusText: "",
  });
  const handleSubmit = async () => {
    if (!feedback) {
      setStatusModal({
        ...statusModal,
        showStatusModal: true,
        status: "error",
        statusText: "Enter Feedback",
      });
    } else {
      await addPaidFeedback(token, feedback, id).then((data) => {
        if (data.error) {
          setStatusModal({
            ...statusModal,
            showStatusModal: true,
            status: "error",
            statusText: data.error,
          });
        } else {
          setStatusModal({
            ...statusModal,
            showStatusModal: true,
            status: "success",
            statusText: data.message,
          });
          history.push("/dashboard");
          window.location.reload();
        }
      });
    }
  };
  return (
    <Base>
      <div className="w_85 mx-auto">
        <BackNavigation title={"Feedback"} color={"#000"} />
        <form className="w_85 mx-auto">
          <div className="feedback_item">
            <label>Feedback</label>
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            ></textarea>
          </div>

          <TkButton
            name={"Submit"}
            style={{ backgroundColor: "#00B23B" }}
            type="submit"
            _onBtnClick={() => handleSubmit()}
          />
        </form>
        <StatusModal
          showModal={statusModal.showStatusModal}
          text={statusModal.statusText}
          status={statusModal.status}
          closeModal={() =>
            setStatusModal({ ...statusModal, showStatusModal: false })
          }
        />
      </div>
    </Base>
  );
};

export default Paidfeedback;
