import webdev from "../../Assets/Course/webdev.png";
import AI from "../../Assets/Course/ai.png";
import appDev from "../../Assets/Course/appdev.png";
import arvr from "../../Assets/Course/arvr.png";
import gamedev from "../../Assets/Course/gamedev.png";
import robotics from "../../Assets/Course/robotics.png";
import python from "../../Assets/Course/python.png";
import UIUX from "../../Assets/Course/UIUX.png";

export const CoursesData = [
  {
    icon: appDev,
    name: "App Development",
  },

  {
    icon: AI,
    name: "Artificial Intelligence",
  },
  {
    icon: python,
    name: "Python Programming",
  },
  {
    icon: arvr,
    name: "AR VR",
  },
  {
    icon: gamedev,
    name: "Games Programming",
  },
  {
    icon: webdev,
    name: "Web Development",
  },
  {
    icon: UIUX,
    name: "UI UX",
  },
  {
    icon: robotics,
    name: "Robotics",
  },
];
