import { base_route } from "../../backend";
import React, { useEffect, useState, useRef } from "react";
import "./commonComponents.css";
import paidCalendar from "../../Assets/Image/paidCalendar.png";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import TkButton from "./TkButton";
import { useHistory } from "react-router-dom";

const InfoBox = ({
  classData,
  name,
  subName,
  grade,
  _onBtnClick,
  _icon,
  renderIcon,
  meetingLink,
  classTime,
}) => {
  const [expand, setExpand] = useState(false);
  const [status, setStatus] = useState("");
  let result;
  const history = useHistory();
  const showClassdate = (date) => {
    if (date != undefined) {
      return (
        date.substring(8, 10) +
        " " +
        date.substring(4, 8) +
        ", " +
        date.substring(16, 25)
      );
    }
    return "";
  };

  const updateCancelled = async () => {
    var classID = classData._id;
    await fetch(`${base_route}/slot/updatecancelled`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ classID }),
    })
      .then((res) => res.json())
      .then((resp) => {
        console.log("Response", resp);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateJoined = async () => {
    //window.open(meetingLink);
    var classID = classData._id;

    await fetch(`${base_route}/slot/updateteacherjoined`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ classID }),
    })
      .then((res) => res.json())
      .then((resp) => {
        console.log("Response", resp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkStatus = () => {
    if (classData.paidClassINR && classData.feedback.length >= 1) {
      return "Completed";
    }
    if (classData.paidClassEINR && classData.feedback.length >= 1) {
      return "Completed";
    }
    if (classData.paidClassINR || classData.paidClassEINR) {
      return "Feedback";
    }
    if (classData.trailCompleted && classData.feedback.length >= 1) {
      return "Completed";
    } else if (classData.trailCompleted) {
      return "Feedback";
    } else if (classData.halfCompleted) {
      return "Half Completed";
    } else if (classData.cancelled) {
      return "Cancelled";
    } else {
      var actualClassTime = new Date(classTime).getTime();
      var diffrence = actualClassTime - new Date().getTime();
      if (diffrence > 600000) {
        return "Upcoming";
      } else if (diffrence <= 600000) {
        if (diffrence < -60 * 60 * 1000) {
          if (classData.isteacherjoined == false) {
            console.log("cancelled API called");
            updateCancelled();
            return "Cancelled";
          } else {
            return "On Going";
          }
        } else if (classData.isteacherjoined) {
          console.log("Teacher joined");
          return "On Going";
        } else if (classData.isteacherjoined == false) {
          console.log("teacher not joined");
          return "Join Now";
        }
      }
    }
  };

  const iconView = (_icon) => {
    if (_icon === "trial") {
      switch (checkStatus()) {
        case "Join Now":
          result = (
            <TkButton
              name={checkStatus()}
              style={{ backgroundColor: "#00B23B" }}
              _onBtnClick={() => {
                updateJoined();
                window.open(meetingLink);
              }}
            />
          );
          break;
        case "On Going":
          result = (
            <TkButton
              name={checkStatus()}
              style={{ backgroundColor: "#e6ca17" }}
              _onBtnClick={_onBtnClick}
            />
          );
          break;

        case "Feedback":
          result = (
            <TkButton
              name={checkStatus()}
              style={{ backgroundColor: "#00B23B" }}
              _onBtnClick={() =>
                history.push(`/dashboard/feedback/${classData._id}`)
              }
            />
          );
          break;

        case "Half Completed":
          result = (
            <TkButton
              name={checkStatus()}
              style={{ backgroundColor: "#e6ca17" }}
            />
          );
          break;

        case "Cancelled":
          result = (
            <TkButton
              name={checkStatus()}
              style={{ backgroundColor: "#ff1e00" }}
            />
          );
          break;
        case "Upcoming":
          result = (
            <>
              <div className="paid_calendar d-flex align-items-center">
                <img src={paidCalendar} alt="" className="mr-2 calendar_img" />
                <span>{showClassdate(classTime)}</span>
              </div>
            </>
          );
          break;
        case "Completed":
          result = (
            <TkButton
              name={checkStatus()}
              style={{ backgroundColor: "#00B23B" }}
            />
          );
          break;
        default:
          result = (
            <TkButton
              name={checkStatus()}
              style={{ backgroundColor: "#00B23B" }}
              _onBtnClick={_onBtnClick}
            />
          );
          break;
      }
      return result;
    } else if (_icon === "paid") {
      switch (checkStatus()) {
        case "Join Now":
          result = (
            <TkButton
              name={checkStatus()}
              style={{ backgroundColor: "#00B23B" }}
              _onBtnClick={() => {
                updateJoined();
                window.open(meetingLink);
              }}
            />
          );
          break;
        case "On Going":
          result = (
            <TkButton
              name={checkStatus()}
              style={{ backgroundColor: "#e6ca17" }}
              _onBtnClick={_onBtnClick}
            />
          );
          break;

        case "Feedback":
          result = (
            <TkButton
              name={checkStatus()}
              style={{ backgroundColor: "#00B23B" }}
              _onBtnClick={() =>
                history.push(`/dashboard/paidfeedback/${classData._id}`)
              }
            />
          );
          break;

        case "Half Completed":
          result = (
            <TkButton
              name={checkStatus()}
              style={{ backgroundColor: "#e6ca17" }}
            />
          );
          break;

        case "Cancelled":
          result = (
            <TkButton
              name={checkStatus()}
              style={{ backgroundColor: "#ff1e00" }}
            />
          );
          break;
        case "Upcoming":
          result = (
            <>
              <div className="paid_calendar d-flex align-items-center">
                <img src={paidCalendar} alt="" className="mr-2 calendar_img" />
                <span>{showClassdate(classTime)}</span>
              </div>
            </>
          );
          break;
        case "Completed":
          result = (
            <TkButton
              name={checkStatus()}
              style={{ backgroundColor: "#00B23B" }}
            />
          );
          break;
        default:
          result = (
            <TkButton
              name={checkStatus()}
              style={{ backgroundColor: "#00B23B" }}
              _onBtnClick={_onBtnClick}
            />
          );
          break;
      }
      return result;
    } else if (_icon === "dropdown") {
      return (
        <>
          {expand ? (
            <IoMdArrowDropup
              size={50}
              color={"#6b0080"}
              onClick={() => setExpand(false)}
            />
          ) : (
            <IoMdArrowDropdown
              size={50}
              color={"#6b0080"}
              onClick={() => setExpand(true)}
            />
          )}
        </>
      );
    } else {
      {
        return renderIcon;
      }
    }
  };

  return (
    <div className="trial_info">
      <div className="trial_info_box d-flex justify-content-between align-items-center mt-3">
        <span className="trial_user_name">{name}</span>
        <span className="trial_subject_name">
          {subName}
          {` Grade: ` + grade}
        </span>
        {iconView(_icon)}
      </div>
      {expand && (
        <div className="expand_text">
          <span>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Delectus
            mollitia laborum quas ducimus voluptas a itaque, numquam doloribus
            quisquam architecto!
          </span>
        </div>
      )}
    </div>
  );
};

export default InfoBox;
