export default function buildCalandar(value) {
  const startDay = value.clone().startOf("month").startOf("week").isoWeekday(4);
  const endDay = value.clone().endOf("month").endOf("week");
  const day = startDay.clone().subtract(1, "day");

  const calendar = [];
  while (day.isBefore(endDay, "day")) {
    calendar.push(
      // day.add(1, "day").clone()
      Array(7)
        .fill(0)
        .map(() => day.add(1, "day").clone())
    );
  }

  return calendar;
}

export const weekData = {
  weekName: ["", "THU", "FRI", "SAT", "SUN", "MON", "TUE", "WED"],
  timings: [
    "06:00 AM",
    "07:00 AM",
    "08:00 AM",
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
    "7:00 PM",
    "8:00 PM",
    "9:00 PM",
    "10:00 PM",
    "11:00 PM",
    "12:00 AM",
    "01:00 AM",
    "02:00 AM",
    "03:00 AM",
    "04:00 AM",
    "05:00 AM",
  ],
};
