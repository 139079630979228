import React, { useState, useContext } from "react";
import BackNavigation from "../../../../Common/Components/BackNavigation";
import { UserContext } from "../../../../App";
import { useHistory } from "react-router";
import Base from "../../../../Common/Components/Base";
import TkDropDown from "../../../../Common/Components/DropDown";
import TkButton from "../../../../Common/Components/TkButton";
import StatusModal from "../../../../Common/Components/StatusModal";
import { createTicket } from "./TicketAPIcalls";
const TicketCreate = () => {
  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: "",
    statusText: "",
  });

  let history = useHistory();
  const [ticketCategory, setTicketCategory] = useState("Registration Related");
  const [file, setFile] = useState(null);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const { userData, token } = useContext(UserContext);
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(subject, ticketCategory, description, file);
    if (!subject || !ticketCategory || !description) {
      setStatusModal({
        ...statusModal,
        showStatusModal: true,
        status: "error",
        statusText: "Enter All the Fields",
      });

      return;
    }
    const data = new FormData();
    data.append("file", file);
    data.append("subject", subject);
    data.append("category", ticketCategory);
    data.append("description", description);
    data.append("profile", "Teacher");
    data.append("name", userData.name);
    createTicket(data, token).then((res) => {
      if (res.error) {
        console.log(res.error);
        setStatusModal({
          ...statusModal,
          showStatusModal: true,
          status: "error",
          statusText: res.error,
        });
        return;
      } else {
        setSubject("");
        setDescription("");
        setFile(null);
        setTicketCategory("Tech Issues Related");
        setStatusModal({
          ...statusModal,
          showStatusModal: true,
          status: "success",
          statusText: "Ticket Created Successfully",
        });
      }
    });
  };
  return (
    <Base>
      <div className="w_85 mx-auto">
        <BackNavigation title={"Create New Ticket"} color={"#000"} />
        <div className="ticket_section ">
          <div className="ticket_top">
            <h1>Ticket Details</h1>
          </div>
          <div className="ticket_info">
            <form>
              <div className="form-group">
                <TkDropDown
                  label="Category*"
                  _onClick={setTicketCategory}
                  value={ticketCategory}
                  items={[
                    "Registration Related",
                    "Training Related",
                    "Late Show/No Show",
                    "Minimun Time Commitment Related",
                    "Tech Issues Related",
                    "HR - Offer Letter/Policy Related",
                    "Leave Related",
                    "Payment Related",
                    "Other Issues",
                  ]}
                />
              </div>
              <div className="form-group ticket_desc">
                <label>Subject*</label>
                <input
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              <div className="form-group ticket_desc">
                <label>Description*</label>
                <textarea
                  id=""
                  rows="10"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
              <div className="form-group attach">
                <span>Attachments</span>
                <label>
                  Choose File
                  <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    className="ticket_attach"
                  />
                </label>
              </div>
              <h6>{file ? file.name : null}</h6>
              <TkButton
                name={"Submit"}
                style={{ backgroundColor: "#6b0080" }}
                _class="mt-5"
                _type="submit"
                _onBtnClick={(e) => handleSubmit(e)}
              />
            </form>
          </div>
        </div>

        <StatusModal
          showModal={statusModal.showStatusModal}
          text={statusModal.statusText}
          status={statusModal.status}
          closeModal={() => {
            statusModal.status === "error"
              ? setStatusModal({ ...statusModal, showStatusModal: false })
              : history.goBack();
          }}
        />
      </div>
    </Base>
  );
};

export default TicketCreate;
