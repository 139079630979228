import React from "react";
import "./components.css";

const ProfileItem = ({ title, content }) => {
	return (
		<div className="mb-5">
			<h5 className="profile_title mb-2">
				<b>{title}</b>
			</h5>
			{content}
		</div>
	);
};

export default ProfileItem;
