import React from "react";
import { ImCross } from "react-icons/im";
import badgeOne from "../../../Assets/Profile/badgeOne.png";
import badgeTwo from "../../../Assets/Profile/badgeTwo.png";
import badgeThree from "../../../Assets/Profile/badgeThree.png";
import badgeFour from "../../../Assets/Profile/badgeFour.png";
import badgeFive from "../../../Assets/Profile/badgeFive.png";
import badgeSix from "../../../Assets/Profile/badgeSix.png";

const BadgeModal = ({ showModal, closeModal }) => {
  return (
    <div className={`modal badge_modal ${showModal && "show_modal"}`}>
      <div className="modal_content ">
        <ImCross size={15} onClick={() => closeModal()} />
        <div className="d-flex align-items-center justify-content-center flex-wrap">
          <img src={badgeOne} alt="badge" className="teacher_badge" />
          <img src={badgeTwo} alt="badge" className="teacher_badge" />
          <img src={badgeThree} alt="badge" className="teacher_badge" />
          <img src={badgeFour} alt="badge" className="teacher_badge" />
          <img src={badgeFive} alt="badge" className="teacher_badge" />
          <img src={badgeSix} alt="badge" className="teacher_badge" />
        </div>
      </div>
    </div>
  );
};

export default BadgeModal;
