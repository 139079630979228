import React from "react";

const ColorDetails = () => {
  return (
    <section className="color_details">
      <div>
        <p className="color_item" style={{ backgroundColor: "#43DCFF" }}>
          AVAILABLE
        </p>
        <p>Class which is available to be allotted as a trial in future</p>
      </div>
      <div>
        <p className="color_item" style={{ backgroundColor: "#09DB31" }}>
          AVAILABLE BLOCKED
        </p>
        <p>Class which is available save to be allotted as a trial in future</p>
      </div>
      <div>
        <p className="color_item" style={{ backgroundColor: "#81007F" }}>
          PAID CLASS
        </p>
        <p>Utilised class which was executed as paid class</p>
      </div>
      <div>
        <p className="color_item" style={{ backgroundColor: "#3F51B5" }}>
          Trial Booked
        </p>
        <p>Utilised class which was executed as trial class</p>
      </div>
      <div>
        <p className="color_item" style={{ backgroundColor: "#FF0000" }}>
          CANCEL
        </p>
        <p>Class which was cancelled</p>
      </div>
    </section>
  );
};

export default ColorDetails;
